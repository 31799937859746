export const events = [
  {
    title:
      "VIRTUAL — BARRELHOUSE MAGAZINE CONVERSATIONS & CONNECTIONS CONFERENCE",
    location: "Online",
    date: "February 6, 2021",
    msDate: new Date("February 6, 2021").getTime(),
    details: "DETAILS (sold out)",
    link: "https://www.writersconnectconference.com/",
  },
  {
    title: "VIRTUAL — STANFORD LUNAR NEW YEAR (VIRTUAL) STREET FESTIVAL 2021!",
    location: "Online",
    date: "February 13, 2021",
    msDate: new Date("February 13, 2021").getTime(),
    details: "DETAILS",
    link: "https://alumni.stanford.edu/get/page/events/details?event_id=33788",
  },
  {
    title: "VIRTUAL — SONOMA COUNTY WRITERS CAMP",
    location: "Online",
    date: "February 25, 2021",
    msDate: new Date("February 25, 2021").getTime(),
    details: "DETAILS",
    link: "https://sonomacountywriterscamp.com/virtual-camp/",
  },
  {
    title:
      "INSTAGRAM LIVE @CLAREMACKWRITES — UK/US BOOKS ACROSS THE POND WITH CLARE MACINTOSH",
    location: "Online",
    date: "March 1, 2021",
    msDate: new Date("March 1, 2021").getTime(),
    details: "1 PM EST",
    link: "",
  },
  {
    title:
      "VIRTUAL — EAST CITY BOOKSHOP, WASHINGTON, DC In Conversation with KATE HOPE DAY (In the Quick)",
    location: "Online",
    date: "March 4, 2021",
    msDate: new Date("March 4, 2021").getTime(),
    details: "7 PM EST DETAILS",
    link: "https://www.eastcitybookshop.com/event/virtual-event-kate-hope-day-quick-conversation-angie-kim",
  },
  {
    title: "VIRTUAL — WOOD DALE PUBLIC LIBRARY, WOOD DALE, IL",
    location: "Online",
    date: "March 18, 2021",
    msDate: new Date("March 18, 2021").getTime(),
    details: "7 – 8 PM CST DETAILS",
    link: "https://events.wooddalelibrary.org/event/4834474",
  },
  {
    title:
      "VIRTUAL — GREECE PUBLIC LIBRARY, ROCHESTER, NY #OneBookReads Program",
    location: "Online",
    date: "March 24, 2021",
    msDate: new Date("March 24, 2021").getTime(),
    details: "6:30 – 8:30 PM (Details will be available closer to the event)",
    link: "",
  },
  {
    title: "NEW YORK, NY — PEN PARENTIS SALON With MARION WINIK",
    location: "New York, NY",
    date: "April 13, 2020",
    msDate: new Date("April 13, 2020").getTime(),
    details: "6 – 7 PM",
    link: "",
  },
  {
    title:
      "VIRTUAL — EAST CITY BOOKSHOP, WASHINGTON, DC IN CONVERSATION WITH JOANN TOMPKINS (WHAT COMES AFTER)",
    location: "Online",
    date: "April 15, 2020",
    msDate: new Date("April 15, 2020").getTime(),
    details: "7 – 8 PM (Details will be available closer to the event)",
    link: "",
  },

  {
    title: "VIRTUAL — DUNEDIN PUBLIC LIBRARY, DUNEDIN, FL",
    location: "Virtual",
    date: "April 17, 2020, 10 – 11 AM",
    msDate: new Date("April 17, 2020 10:00:00").getTime(),
    details: "Details will be available closer to the event",
    link: "",
  },
  {
    title: "VIRTUAL — BARDS ALLEY BOOKS, FAIRFAX, VA",
    location: "Virtual",
    date: "April 20, 2020, 7 – 8 PM",
    msDate: new Date("April 20, 2020 19:00:00").getTime(),
    details:
      "In Conversation with DAVID BALDACCI (Launch event for A GAMBLING MAN)",
    link: "",
  },
  {
    title: "CHEVY CHASE, MD — THE WOMAN’S CLUB OF CHEVY CHASE FUNDRAISER",
    location: "Chevy Chase, MD",
    date: "April 26, 2021, 12 – 1:30 PM",
    msDate: new Date("April 26, 2021 12:00:00").getTime(),
    details: "Details will be available closer to the event",
    link: "",
  },
  {
    title: "VIRTUAL — WOMEN’S NATIONAL BOOK ASSOCIATION",
    location: "Virtual",
    date: "April 29, 2021, 7:30 PM",
    msDate: new Date("April 29, 2021 19:30:00").getTime(),
    details: "Great Group Reads 2020 Bookwoman Book Club",
    link: "",
  },
  {
    title: "LAKE FOREST, IL — A NOVEL AFFAIR",
    location: "Lake Forest, IL",
    date: "October 8-10, 2021",
    msDate: new Date("October 8, 2021").getTime(),
    details: "DETAILS and PREVIEW by REBECCA MAKKAI",
    link: "",
  },
  {
    title: "VIRTUAL – PBS BETWEEN THE COVERS",
    location: "Virtual",
    date: "May 23, 2020",
    msDate: new Date("May 23, 2020").getTime(),
    details: "Recorded interview available HERE",
    link: "https://www.youtube.com/watch?v=0B9IpPydQuY",
  },
  {
    title: "WASHINGTON, D.C. – POLITICS & PROSE BOOKSTORE",
    location: "Washington, D.C.",
    date: "April 27, 2019, 6:00 PM – 7:30 PM",
    msDate: new Date("April 27, 2019 18:00:00").getTime(),
    details:
      "MIRACLE CREEK Launch: A Conversation with SUSAN GOLOMB (Literary Agent) and SARAH CRICHTON (Publisher & Editor)",
    link: "https://www.youtube.com/watch?v=ESJVDI4y4b4",
  },
  {
    title: "MACMILLAN BOOK + AUTHOR (WITH TÉA OBREHT)",
    location: "VIRTUAL",
    date: "July 30, 2020, 7 – 8 PM",
    msDate: 1596121200000,
    details: "",
    link: "https://www.crowdcast.io/e/apocaxnl",
  },
  {
    title: "WISCONSIN BOOK FESTIVAL",
    location: "VIRTUAL",
    date: "October 15, 2020, 8:30 PM",
    msDate: 1602808200000,
    details: "Joint event with KILEY REID, moderated by DANTIEL MONIZ",
    link: "https://www.crowdcast.io/e/wbf-miracle-creek-such-a-fun-age/register?utm_source=website&utm_medium=event-page",
  },
  {
    title:
      "VIRTUAL LITERARY HAPPY HOUR (WRITING CRAFT) — HUGO HOUSE, SEATTLE, WA",
    location: "VIRTUAL",
    date: "July 10, 2020, 5 – 6 PM PST",
    msDate: 1594405200000,
    details: "DETAILS",
    link: "https://www.facebook.com/hugohouse/videos/311490466566415",
  },
  {
    title: "WESTPORT LIBRARY, WESTPORT, CT",
    location: "VIRTUAL",
    date: "June 15, 2020",
    msDate: 1592198400000,
    details: "In Conversation with JULIA PHILLIPS and MELISSA RIVERO",
    link: "https://www.youtube.com/watch?v=Sgjj3qbx97M",
  },
  {
    title: "NEW HAMPSTEAD HIGH SCHOOL, SAVANNAH BOOK FESTIVAL @ SCHOOLS",
    location: "SAVANNAH, GA",
    date: "February 14, 2020",
    msDate: 1581686400000,
    details: "Miracle Creek Mock Trial",
    link: "https://www.youtube.com/watch?v=l2MWHUrHAe8&feature=youtu.be",
  },
  {
    title:
      "VIRTUAL AUTHOR TALK — CARROLL COUNTY PUBLIC LIBRARY & A LIKELY STORY BOOKSTORE, MD",
    location: "VIRTUAL",
    date: "June 24, 2020",
    msDate: 1592985600000,
    details: "",
    link: "https://www.carrollmediacenter.org/episode/author-talk-angie-kim",
  },
  {
    title: "SILENT BOOK CLUB DISCUSSION",
    location: "VIRTUAL",
    date: "April 21, 2020, 8 PM",
    msDate: 1587475200000,
    details: "",
    link: "https://youtu.be/o-HHnSLQ7-Q",
  },
];
