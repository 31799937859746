import { useEffect, useState } from "react";
import { colors } from "../theme/colors";
import { Typography } from "@mui/material";

const Countdown = () => {
  const [timeRemaining, setTimeRemaining] = useState(0);

  const generateTime = () => {
    let pubMS = 1693281600000;
    let unformattedTimeRemaining = pubMS - Date.now(); //returns ms until book release

    let hours;
    let minutes;
    let seconds;
    let tempDate = "";

    let now = new Date();
    let estOffset = -5; // EST is UTC-5
    let localOffset = now.getTimezoneOffset() / 60;
    let totalOffset = estOffset + localOffset;
    now.setHours(now.getHours() + totalOffset);
    let midnight = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1,
      0,
      0,
      0
    );
    let msToMidnight = Math.floor(midnight) - now;

    if (unformattedTimeRemaining > 2610900000 + 1800000) {
      tempDate +=
        Math.floor(unformattedTimeRemaining / (2610900000 + 1800000)) +
        " months ";
      unformattedTimeRemaining -=
        Math.floor(unformattedTimeRemaining / (2610900000 + 1800000)) *
        (2610900000 + 1800000);
    }
    if (unformattedTimeRemaining > 86400000) {
      tempDate += Math.floor(unformattedTimeRemaining / 86400000) + " days ";
      unformattedTimeRemaining -=
        Math.floor(unformattedTimeRemaining / 86400000) * 86400000;
    }

    //calc hours
    if (msToMidnight > 3600000) {
      hours = Math.floor(msToMidnight / 3600000);

      if (hours > 9) {
        tempDate += hours + ":";
      } else {
        tempDate += "0" + hours + ":";
      }

      msToMidnight -= hours * 3600000;
    }
    //calc minutes
    if (msToMidnight > 60000) {
      minutes = Math.floor(msToMidnight / 60000);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      tempDate += minutes + ":";
      msToMidnight -= minutes * 60000;
    } else tempDate += "00:";
    if (msToMidnight > 1000) {
      seconds = Math.floor(msToMidnight / 1000);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      tempDate += seconds;
    } else tempDate += "00";
    setTimeRemaining(tempDate);
  };

  useEffect(() => {
    let interval = setInterval(() => {
      generateTime();
    }, [1000]);

    return () => clearInterval(interval);
  }, []);
  return (
    <Typography
      variant="h1"
      style={{ fontSize: "2.3em", color: colors.primaryColor }}
    >
      {timeRemaining}
    </Typography>
  );
};

export default Countdown;
