import { colors } from "../../theme/colors";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import PublicHeader from "../../components/PublicHeader";
import { bookData } from "../../assets/bookData";
import { useEffect, useState } from "react";
import "./Book.css";
import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import {
  happiness_falls_retailers,
  miracle_creek_retailers,
} from "../../assets/retailers";
import { DataStore } from "aws-amplify";
import { Text } from "../../models";
import { useAuthContext } from "../../contexts/AuthContext";
import FurtherResourcesDialog from "./FurtherResourcesDialog";

const Book = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [editingModalOpen, setEditingModalOpen] = useState(false);
  const [furtherResourcesDialogOpen, setFurtherResourcesDialogVisible] =
    useState(false);
  const { dbUser } = useAuthContext();

  let params = useParams();

  useEffect(() => {
    if (params.book === "miracle_creek") {
      DataStore.query(Text, (t) =>
        t.type.beginsWith("books/miracle_creek")
      ).then(setSelectedReviews);
      setRetailers(miracle_creek_retailers);
    } else {
      DataStore.query(Text, (t) =>
        t.type.beginsWith("books/happiness_falls")
      ).then(setSelectedReviews);
      setRetailers(happiness_falls_retailers);
    }
    setData(
      bookData.find(
        (book) => book.title.toLowerCase() === params.book.replace("_", " ")
      )
    );
  }, [params.book]);

  if (!data.image) {
    return null;
  }

  const editText = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    DataStore.query(Text, selectedReviews[0].id).then((res) =>
      DataStore.save(
        Text.copyOf(res, (updated) => {
          updated.body = formData.get("body");
        })
      )
    );

    setEditingModalOpen(false);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <PublicHeader currentRoute={"/books"} />
      <div style={{ paddingTop: "7em" }} className="sectionContainer books">
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FurtherResourcesDialog
            furtherResourcesDialogOpen={furtherResourcesDialogOpen}
            setFurtherResourcesDialogOpen={setFurtherResourcesDialogVisible}
          />
          <img
            className="cover"
            src={require(`../../assets/${data?.image}`)}
            alt="miracle creek"
          ></img>
          {params.book === "happiness_falls" && (
            <div
              style={{
                display: "flex",
                gap: "1em",
                width: "80%",
                marginTop: "1.2em",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{
                  fontFamily: "TisaSansPro-Bold",
                  flex: 1,
                }}
                onClick={() =>
                  window.open(
                    "https://sites.prh.com/happiness-falls-author-qa",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                Q&A with Angie
              </Button>

              <Button
                variant="contained"
                color="secondary"
                size="large"
                style={{
                  fontFamily: "TisaSansPro-Bold",
                  flex: 1,
                }}
                onClick={() =>
                  navigate("/books/happiness_falls/further_resources")
                }
              >
                Further Resources
              </Button>
            </div>
          )}
        </div>
        <div className="book-description">
          <Typography
            variant="h1"
            style={{
              fontSize: "3em",
              padding: "0em 0em",
              color: colors.primaryColor,
              marginBottom: 0,
            }}
          >
            {params.book.replace("_", " ") === "happiness falls"
              ? `"We didn't call the police right away."`
              : params.book.replace("_", " ")}
          </Typography>
          {params.book.replace("_", " ") === "happiness falls" && (
            <Typography
              variant="h2"
              style={{
                fontSize: "1.8em",
                fontWeight: 600,
                marginBottom: ".5em",
              }}
            >
              Coming August 29 2023
            </Typography>
          )}
          <div
            style={{
              display: "flex",
              marginTop: "-1em",
              marginBottom: "1.5em",
              gap: "1em",
            }}
          >
            {data.mainHighlights.map((highlight) => (
              <h2 style={{ fontSize: "1.5em" }}>{highlight}</h2>
            ))}
          </div>
          <p style={{ fontSize: "1.35em" }}>
            {data.description1}
            <br />
            <br />
            {data.description2}
            <br />
            <br />
            {data.description3 ? data.description3 : null}
          </p>
          {
            <div
              className="pre-order-container"
              style={{
                backgroundColor: `${colors.secondaryColor}22`,
                borderRadius: 8,
                padding: "2em 1em",
                marginTop: "3em",
              }}
            >
              {!data.description3 ? (
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "2.5em",
                    color: "black",
                    marginBottom: ".5em",
                    textAlign: "center",
                  }}
                >
                  {"Buy Now"}
                </Typography>
              ) : (
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "2em",
                    color: "black",
                    cursor: "pointer",
                    marginBottom: ".5em",
                    transition:
                      "opacity ease-out 500ms, font-size ease-out 500ms",
                    textAlign: "center",
                    ":hover": {
                      opacity: 0.5,
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "https://woobox.com/yrnsj3",
                      "_blank",
                      "noreferrer"
                    )
                  }
                >
                  Pre-Order And{" "}
                  <b
                    style={{
                      textDecoration: "underline",
                      color: colors.primaryColor,
                    }}
                  >
                    Receive a Gift
                  </b>
                </Typography>
              )}

              <Grid container className="preorder-list">
                {retailers.map((retailer, index) => {
                  return (
                    <Grid
                      item
                      xs={3}
                      style={{ padding: "0em 1em" }}
                      className="preorder-image-container"
                    >
                      <div style={{ padding: "5px" }}>
                        <img
                          style={{
                            maxWidth: "100%",
                            resize: "vertical",
                            height: index === 0 ? "4em" : "2em",
                          }}
                          className={`preorder-image ${
                            index === 0 ? "no-greyscale" : ""
                          }`}
                          src={retailer.image}
                          onClick={() => {
                            window.open(retailer.link, "_blank", "noreferrer");
                          }}
                        ></img>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          }
        </div>
      </div>
      <div
        className={`background-image books ${
          params.book === "miracle_creek" ? "mc" : ""
        }`}
      >
        <div style={{ paddingTop: "7em" }} className="sectionContainer books">
          <Grid xs={12} rowSpacing={5} columnSpacing={5} container>
            {data.bestBookOfTheYear.length ? (
              <Grid item xs={6}>
                <h1 style={{ fontSize: "2.5em", color: colors.primaryColor }}>
                  A Best Book of the Year
                </h1>
                <Typography
                  variant="h5"
                  component="h5"
                  fontStyle="italic"
                  color={"white"}
                >
                  {data.bestBookOfTheYear.join(" • ")}
                </Typography>
              </Grid>
            ) : null}
            {data.featuredAwards.length ? (
              <Grid item xs={6}>
                <h1 style={{ fontSize: "2.5em", color: colors.primaryColor }}>
                  Awards
                </h1>
                <Typography
                  variant="h5"
                  component="h5"
                  fontStyle="italic"
                  color={"white"}
                >
                  {data.featuredAwards.join(" • ")}
                </Typography>
              </Grid>
            ) : null}
            {data.criticalAcclaim ? (
              <Grid item xs={12}>
                <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6em",
                  }}
                >
                  {selectedReviews?.[0]?.body
                    ?.split("|")
                    .filter((s, index) => index < 2)
                    .map((text) => (
                      <p
                        style={{
                          margin: 0,
                          fontSize: "2em",
                          textAlign: "left",
                          color: "white",
                        }}
                      >
                        {text.substring(
                          0,
                          text.indexOf("%%") !== -1
                            ? text.indexOf("%%")
                            : text.indexOf("—") !== -1
                            ? text.indexOf("—")
                            : text.indexOf("—")
                        )}
                        <br />
                        <b style={{ fontSize: ".9em" }}>
                          {text.substring(
                            text.indexOf("%%") !== -1
                              ? text.indexOf("%%") + 2
                              : text.indexOf("—") !== -1
                              ? text.indexOf("—")
                              : text.indexOf("—")
                          )}
                        </b>
                      </p>
                    ))}
                </div>
              </Grid>
            ) : (
              <>
                <Grid item xs={6}>
                  <h1 style={{ fontSize: "2.5em", color: colors.primaryColor }}>
                    Honors
                  </h1>
                  <Typography
                    variant="h5"
                    component="h5"
                    fontStyle="italic"
                    color={"white"}
                  >
                    {data.honors.join(" • ")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <h1 style={{ fontSize: "2.5em", color: colors.primaryColor }}>
                    Lists & Recs
                  </h1>
                  <Typography
                    variant="h5"
                    component="h5"
                    fontStyle="italic"
                    color={"white"}
                  >
                    {data.listsAndRecs.join(" • ")}
                  </Typography>
                </Grid>
              </>
            )}
            {data.translations ? (
              <Grid item xs={12}>
                <h1 style={{ fontSize: "2.5em", color: "white" }}>
                  Translated into{" "}
                  <span
                    style={{ fontSize: "1.2em", color: colors.primaryColor }}
                  >
                    20 languages
                  </span>
                </h1>
              </Grid>
            ) : null}
          </Grid>
        </div>
      </div>
      {dbUser?.access === "admin" && selectedReviews?.length && (
        <Modal
          open={editingModalOpen}
          onClose={() => {
            setEditingModalOpen(false);
          }}
        >
          <Box
            component="form"
            onSubmit={editText}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              borderRadius: "12px",
              boxShadow: 24,
              p: 4,
              gap: "1em",
              minWidth: "40dvw",
            }}
          >
            <h1
              style={{
                fontSize: "2.5em",
                color: colors.primaryColor,
                margin: 0,
              }}
            >
              Edit{" "}
              {selectedReviews[0].type.substring(
                selectedReviews[0].type.indexOf("/") + 1
              )}
            </h1>
            <p
              style={{
                fontSize: "3em",
                color: colors.primaryColor,
                marginBottom: ".5em",
                fontFamily: "TisaSansPro-Bold",
                fontSize: "1.5em",
                color: "black",
                marginTop: "-.75em",
              }}
            >
              Add | between each review entry (look at past reviews for
              examples) and %% between the quote and author (look at David
              Baldacci) Top two are featured for HF.
            </p>
            <TextField
              name="body"
              label="body"
              required
              color="secondary"
              multiline
              defaultValue={selectedReviews[0].body}
              maxRows={20}
            />

            <Button type="submit">Save</Button>
          </Box>
        </Modal>
      )}
      {selectedReviews?.length ? (
        <div
          style={{ paddingTop: "7em", gap: "0em" }}
          className={`sectionContainer books ${
            dbUser?.access === "admin" && "editable"
          }`}
          onClick={() => setEditingModalOpen(true)}
        >
          <h1
            style={{
              fontSize: "3em",
              margin: 0,
              marginBottom: "1em",
              color: colors.primaryColor,
            }}
          >
            Selected Reviews & Praise
          </h1>
          <div style={{ display: "flex", flexDirection: "column", gap: "2em" }}>
            {selectedReviews?.[0]?.body
              ?.split("|")
              .filter((s, index) => index > 1)
              .map((text) => (
                <p style={{ margin: 0, fontSize: "1.5em", textAlign: "left" }}>
                  {text.substring(
                    0,
                    text.indexOf("%%") !== -1
                      ? text.indexOf("%%")
                      : text.indexOf("—") !== -1
                      ? text.indexOf("—")
                      : text.indexOf("—")
                  )}
                  <br />
                  <b style={{ fontSize: ".9em" }}>
                    {text.substring(
                      text.indexOf("%%") !== -1
                        ? text.indexOf("%%") + 2
                        : text.indexOf("—") !== -1
                        ? text.indexOf("—")
                        : text.indexOf("—")
                    )}
                  </b>
                </p>
              ))}
          </div>
        </div>
      ) : (
        ""
      )}

      <Footer />
    </div>
  );
};

export default Book;
