import {
  Button,
  Modal,
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
  Alert,
  Snackbar,
} from "@mui/material";

import { DataStore, Predicates, SortDirection } from "aws-amplify";
import React, { useEffect, useState } from "react";

import Footer from "../../components/Footer";

import NewsModule from "../../components/NewsModule";
import PublicHeader from "../../components/PublicHeader";
import { useAuthContext } from "../../contexts/AuthContext";
import { Post } from "../../models";
import { colors } from "../../theme/colors";
import "./News.css";
import { AddOutlined, SaveOutlined } from "@mui/icons-material";

const News = () => {
  const [news, setNews] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const { dbUser } = useAuthContext();
  const [loadedIn, setLoadedIn] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const newsSubscription = DataStore.observeQuery(
      Post,
      (p) => p.or((p) => [p.type.eq("BIO"), p.type.eq("NEWS")]),
      {
        sort: (p) => p.featured(SortDirection.ASCENDING),
      }
    ).subscribe((res) => setNews(res.items));
    setLoadedIn(true);

    return () => newsSubscription.unsubscribe();
  }, []);

  const createNewPost = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    if (formData.get("featured")) {
      news.map((news) => {
        DataStore.save(
          Post.copyOf(news, (updated) => {
            updated.featured = news.featured + 1;
          })
        );
      });
    }
    DataStore.save(
      new Post({
        type: "NEWS",
        title: formData.get("title"),
        body: formData.get("body"),
        link: formData.get("link"),
        linkText: formData.get("linkText"),
        featured: formData.get("featured") ? 0 : news.length,
      })
    );

    setModalOpen(false);
  };

  const saveNews = async () => {
    let isChanged = false;
    for (let i = 0; i < news.length; i++) {
      await DataStore.query(Post, news[i].id).then((post) => {
        if (post.featured !== i) {
          isChanged = true;
          DataStore.save(
            Post.copyOf(post, (updated) => {
              updated.featured = i;
            })
          );
        }
      });
    }
    isChanged
      ? alert("New order saved")
      : alert("Please make changes before saving a new order");
  };

  return (
    <div className={`container ${loadedIn ? "loadedIn" : ""}`}>
      <Snackbar open={snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{
            width: "100%",
            fontSize: "1.2em",
            display: "flex",
            alignItems: "center",
          }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <PublicHeader currentRoute={"/news"} />
      <div
        style={{
          paddingTop: "7em",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          className="sectionContainer"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "5dvh",
            paddingTop: 0,
            gap: 0,
          }}
        >
          <h1 style={{ ...styles.headerText }}>News and Highlights</h1>

          {dbUser?.access === "admin" ? (
            <Box sx={{ display: "flex", width: "100%", marginTop: 1, gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<AddOutlined sx={{ color: "white" }} />}
                fullWidth
                sx={{
                  fontFamily: "TisaSansPro-Bold",
                  flex: 1,
                }}
                onClick={() => setModalOpen(true)}
              >
                Add new event
              </Button>
              <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box
                  component="form"
                  onSubmit={createNewPost}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "white",
                    borderRadius: "12px",
                    boxShadow: 24,
                    p: 4,
                    gap: "1em",
                    minWidth: "40dvw",
                  }}
                >
                  <h1 style={{ fontSize: "2.5em", color: colors.primaryColor }}>
                    Create News
                  </h1>
                  <TextField
                    name="title"
                    label="title"
                    required
                    color="secondary"
                  />
                  <TextField
                    name="body"
                    label="body"
                    required
                    color="secondary"
                  />
                  <TextField
                    name="linkText"
                    label="hyperlink label"
                    color="secondary"
                  />
                  <TextField name="link" label="link" color="secondary" />
                  <FormControlLabel
                    label="Feature Post"
                    name="featured"
                    control={<Checkbox value="featured" color="secondary" />}
                  ></FormControlLabel>
                  <Button type="submit">Create</Button>
                </Box>
              </Modal>
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  fontFamily: "TisaSansPro-Bold",
                  bgcolor: "#888888",
                  ":hover": {
                    bgcolor: "#666666"
                  }
                }}
                startIcon={<SaveOutlined sx={{ color: "white" }} />}
                onClick={saveNews}
              >
                Save New Order
              </Button>
            </Box>
          ) : null}
        </div>

        <div
          className="sectionContainer"
          style={{
            justifyContent: "flex-start",
            flexDirection: "column",
            gap: ".5em",
            paddingBottom: "7em",
          }}
        >
          {news.map((news, index) => {
            return (
              <NewsModule
                id={news.id}
                image={news.image}
                title={news.title}
                description={news.body}
                link={news.link}
                linkTitle={news.linkText}
                index={index}
                setLocal={setNews}
                setReRender={setReRender}
                setSnackbarOpen={setSnackbarOpen}
              />
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
};

const styles = {
  defaultText: {
    fontFamily: "TisaSansPro",
    color: colors.primaryColor,
  },
  headerText: {
    fontSize: "3em",
    color: colors.primaryColor,
  },
};

export default News;
