// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Text, Event, Post, User } = initSchema(schema);

export {
  Text,
  Event,
  Post,
  User
};