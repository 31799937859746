import {
  colors,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import { DataStore } from "aws-amplify";
import { useEffect } from "react";
import "./CustomImageList.css";
import { Post } from "../models";

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const CustomImageList = ({ itemData }) => {
  return (
    <div style={{ width: "100%", flexWrap: "wrap", display: "flex" }}>
      {itemData
        .filter((item) => item.featured)
        .map((item, index) => {
          let image = require(`../assets/${item.image}`);
          const cols = index === 0 ? 2 : 1;
          const rows = index === 0 ? 1 : 1;

          return (
            <ImageListItem
              className="image-list-item"
              key={item.image}
              cols={cols}
              rows={rows}
              style={{ width: index === 0 ? "100%" : "50%" }}
              onClick={() => window.open(item.link, "_blank", "noreferrer")}
            >
              <img
                {...srcset(image, 150, 200, rows, cols)}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                sx={{
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                    "rgba(0,0,0,0.5) 70%, rgba(0,0,0,0) 100%)",
                  fontFamily: "TisaSansPro-Bold",
                }}
                title={item.title}
                subtitle={item.body}
                position="top"
                actionPosition="left"
              />
            </ImageListItem>
          );
        })}
    </div>
    // </ImageList>
  );
};

export default CustomImageList;
