import * as React from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { useNavigate } from "react-router-dom";

import PublicHeader from "../../components/PublicHeader";
import Footer from "../../components/Footer";
import "./Contact.css";
import { colors } from "../../theme/colors";
import { FaGoodreadsG } from "react-icons/fa";
import { FacebookTwoTone, Instagram, Twitter } from "@mui/icons-material";
import ContactModule from "./ContactModule";
const Contact = () => {
  const navigate = useNavigate();

  const [firstNameError, setFirstNameError] = React.useState(null);
  const [lastNameError, setLastNameError] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [subjectError, setSubjectError] = React.useState(null);
  const [messageError, setMessageError] = React.useState(null);
  const [isLoadedIn, setIsLoadedIn] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let error = false;
    if (data.get("firstName") === "") {
      setFirstNameError({ message: "First name cannot be empty" });
      error = true;
    } else {
      setFirstNameError(null);
    }
    if (data.get("lastName") === "") {
      setLastNameError({ message: "Last name cannot be empty" });
      error = true;
    } else {
      setLastNameError(null);
    }
    if (data.get("email") === "") {
      setEmailError({ message: "Email cannot be empty" });
      error = true;
    } else if (
      !data.get("email").includes("@") ||
      !data.get("email").includes(".")
    ) {
      setEmailError({ message: "Email not valid" });
      error = true;
    } else {
      setEmailError(null);
    }
    if (data.get("subject") === "") {
      setSubjectError({ message: "Subject cannot be empty" });
      error = true;
    } else {
      setSubjectError(null);
    }
    if (data.get("message") === "") {
      setMessageError({ message: "Message cannot be empty" });
      error = true;
    } else {
      setMessageError(null);
    }
    if (error) {
      return;
    } else return; //SEND EMAIL
  };

  const contactInfo = [
    {
      name: "Susan Golomb",
      role: "Literary Agent",
      company: "Writers House",
      link: "sgolomb@writershouse.com",
    },
    {
      name: "Jason Richman",
      company: "United Talent Agency",
      link: "RichmanJ@unitedtalent.com",
      role: "Film / TV Agent",
    },
    {
      name: "Kimberly Burns",
      company: "Broadside Publicity",
      role: "Publicist",
      link: "KB@broadsidepr.com",
    },
    {
      name: "Christie Hinrichs",
      company: "Director, Authors Unbound",
      role: "Speaking Agent",
      link: "Christie@authorsunbound.com",
      subText: "Click here for Angie Kim's speaking profile page",
      subTextLink: "https://authorsunbound.com/angie-kim/",
    },
  ];

  /** both publicists
   * Michelle Jasmine, mjasmine@penguinrandomhouse.com
Erin Richards, erichards@penguinrandomhouse.com
   */
  const happinessFallsContacts = [
    {
      name: "Michelle Jasmine",
      company: "Penguin Random House",
      link: "mjasmine@penguinrandomhouse.com",
      role: "Publicist",
    },
    {
      name: "Erin Richards",
      company: "Penguin Random House",
      link: "erichards@penguinrandomhouse.com",
      role: "Publicist",
    },
  ];

  /**publicist
   * Lottchen Shivers, lottchen.shivers@fsgbooks.com
   */
  const miracleCreekContatcts = [
    {
      name: "Lottchen Shivers",
      company: "Farrar, Straus & Giroux",
      link: "lottchen.shivers@fsgbooks.com",
      role: "Publicist",
    },
  ];

  return (
    <div className="container loadedIn contact">
      <PublicHeader />
      <Grid
        wrap="wrap"
        className="section-container contact"
        style={{ paddingTop: "7em" }}
        container
      >
        <Grid
          xs={12}
          container
          className="contact-info"
          style={{
            alignItems: "flex-start",
          }}
        >
          <Grid
            container
            style={{ justifyContent: "center" }}
            xs={12}
            rowSpacing={2}
            md={5.5}
          >
            <Grid item xs={12}>
              <ContactModule
                name={"Angie Kim"}
                link={"angiekimbooks@gmail.com"}
                role={"Author"}
                isOriginalAuthor={true}
              />
            </Grid>

            {contactInfo.map((contact) => {
              return (
                <Grid item xs={12}>
                  <ContactModule
                    name={contact.name}
                    company={contact.company}
                    link={contact.link}
                    role={contact.role}
                    subText={contact.subText}
                    subTextLink={contact.subTextLink}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Contact;
