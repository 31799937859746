import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { useNavigate } from "react-router-dom";
import { Auth, DataStore } from "aws-amplify";
import { User } from "../../models";
import { FormControl } from "@mui/material";

export default function SignUp() {
  const navigate = useNavigate();

  const [firstNameError, setFirstNameError] = React.useState(null);
  const [lastNameError, setLastNameError] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);
  const [agreeError, setAgreeError] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let error = false;
    if (data.get("firstName") === "") {
      setFirstNameError({ message: "First name cannot be empty" });
      error = true;
    } else {
      setFirstNameError(null);
    }
    if (data.get("lastName") === "") {
      setLastNameError({ message: "Last name cannot be empty" });
      error = true;
    } else {
      setLastNameError(null);
    }
    if (data.get("email") === "") {
      setEmailError({ message: "Email cannot be empty" });
      error = true;
    } else if (
      !data.get("email").includes("@") ||
      !data.get("email").includes(".")
    ) {
      setEmailError({ message: "Email not valid" });
      error = true;
    } else {
      setEmailError(null);
    }
    if (data.get("password") === "") {
      setPasswordError({ message: "Password cannot be empty" });
      error = true;
    } else if (data.get("password").length < 8) {
      setPasswordError({ message: "Password must be at least 8 characters" });
      error = true;
    } else if (data.get("password").includes(" ")) {
      setPasswordError({ message: "Password cannot contain spaces" });
      error = true;
    }
    else {
      setPasswordError(null);
    }
    if (data.get("checkbox") !== "checked") {
      setAgreeError({ message: "You must agree to my demands" });
      error = true;
    } else {
      setAgreeError(null);
    }
    if (error) {
      console.log("error found");
      setLoading(false);
      return;
    }

    try {
      await Auth.signUp({
        username: data.get("email").toLowerCase(),
        password: data.get("password"),
      }).then((authUser) => {
        DataStore.save(
          new User({
            name: data.get("firstName") + " " + data.get("lastName"),
            email: data.get("email").toLowerCase(),
            sub: authUser.userSub,
          })
        );
      });

      navigate("/confirm/" + data.get("email").toLowerCase());
    } catch (err) {
      if (
        err.message.toLowerCase().includes("username")
      ) {
        setEmailError({ message: "Email not valid" });
      } else if (
        err.message === "An account with the given email already exists."
      ) {
        setEmailError({ message: "Account already exists" });
      }
      if(err.message.toLowerCase().includes("password")){
        setPasswordError({ message: "Password not valid" });
      }

      console.log(err.message);
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                color="secondary"
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                error={firstNameError ? true : false}
                helperText={firstNameError ? firstNameError.message : null}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                color="secondary"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                error={lastNameError ? true : false}
                helperText={lastNameError ? lastNameError.message : null}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="secondary"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                inputProps={{autoCapitalize: "none"}}
                error={emailError ? true : false}
                helperText={emailError ? emailError.message : null}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="secondary"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                error={passwordError ? true : false}
                helperText={passwordError ? passwordError.message : null}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl required error={agreeError ? true : false}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="checked"
                      id="checkbox"
                      name="checkbox"
                      color="secondary"
                    />
                  }
                  sx={{ color: agreeError ? "#f44336" : null }}
                  label="I agree to the terms and conditions"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Button
            color="secondary"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, color: "white", fontWeight: "bold" }}
          >
            {loading ? "Loading..." : "Sign Up"}
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link color="secondary" href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
