import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContext";
import { colors } from "../theme/colors";

const ReviewModule = ({
  title,
  body,
  name,
  link,
  setReviews,
  index,
  setReRender,
}) => {
  const { dbUser } = useAuthContext();
  const handleSwap = async (type) => {
    if (type === "decrease") {
      setReviews((old) => {
        if (index > old.length - 2) return old;
        let newNews = old;

        let tempNews = newNews[index + 1];
        newNews[index + 1] = { ...old[index] };
        newNews[index] = { ...tempNews };

        return newNews;
      });
    } else if (type === "increase") {
      setReviews((old) => {
        if (index < 1) return old;
        let newNews = old;
        newNews = newNews;
        let tempNews = newNews[index - 1];
        newNews[index - 1] = { ...old[index] };
        newNews[index] = { ...tempNews };
        return newNews;
      });
    } else return null;
    setReRender((old) => !old);
  };
  return (
    <div
      className="module-container news"
      style={{ display: "flex"}}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: '100%',
          alignItems: "flex-start",
          flexWrap: "wrap",
          textAlign: "left",
        }}
        onClick={() => {
          window.open(link, "_blank", "noreferrer");
        }}
      >
        {title && (
          <span
            style={{
              ...styles.defaultText,
              color: "black",
              fontSize: "1.2em",
              fontFamily: "TisaSansPro-Bold",
            }}
          >
            {title}
          </span>
        )}
        {body && (
          <span style={{ ...styles.defaultText, color: "black" }}>{body}</span>
        )}
        {name && (
          <Link
            onClick={() => {
              window.open(link, "_blank", "noreferrer");
            }}
            style={{
              ...styles.defaultText,
              color: colors.primaryColor,
              fontStyle: "italic",
              alignSelf: "flex-end",
              fontWeight: "bold",
              textDecoration: "none",
            }}
          >
            -{name}
          </Link>
        )}
      </div>
      {dbUser?.access === "admin" ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            marginLeft: 'auto',
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleSwap("increase");
            }}
          >
            <ArrowDropUp sx={{ fontSize: "3em" }} />
          </IconButton>

          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleSwap("decrease");
            }}
          >
            <ArrowDropDown sx={{ fontSize: "3em" }} />
          </IconButton>
        </div>
      ) : null}
    </div>
  );
};

const styles = {
  defaultText: {
    fontFamily: "TisaSansPro",
    color: colors.primaryColor,
  },
  headerText: {
    fontSize: "3em",
    color: colors.primaryColor,
    marginBottom: ".5em",
  },
};

export default ReviewModule;
