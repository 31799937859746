import { ArrowDropDown, ArrowDropUp, Delete } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { useAuthContext } from "../contexts/AuthContext";
import { colors } from "../theme/colors";
import "./NewsModule.css";
import { DataStore } from "aws-amplify";
import { Event, Post } from "../models";

const NewsModule = ({
  date,
  title,
  description,
  link,
  image,
  id,
  index,
  setLocal,
  setReRender,
  type,
  setSnackbarOpen,
}) => {
  const { dbUser } = useAuthContext();

  const handleSwap = async (type) => {
    if (type === "decrease") {
      setLocal((old) => {
        if (index > old.length - 2) return old;
        let newNews = old;

        let tempNews = newNews[index + 1];
        newNews[index + 1] = { ...old[index] };
        newNews[index] = { ...tempNews };

        return newNews;
      });
    } else if (type === "increase") {
      setLocal((old) => {
        if (index < 1) return old;
        let newNews = old;
        newNews = newNews;
        let tempNews = newNews[index - 1];
        newNews[index - 1] = { ...old[index] };
        newNews[index] = { ...tempNews };
        return newNews;
      });
    } else return null;
    setReRender((old) => !old);
  };

  const handleDelete = () => {
    if (type === "event") {
      setSnackbarOpen({
        type: "error",
        message: "Are you sure you want to delete this news instance?",
        action: (
          <Button
            color="inherit"
            size="small"
            onClick={async () => {
              setSnackbarOpen(null);
              try {
                await DataStore.delete(Event, id);
                setSnackbarOpen({
                  type: "success",
                  message: "Successfully deleted events instance!",
                });
              } catch (err) {
                setSnackbarOpen({
                  type: "error",
                  message: "Unexpected error deleting event",
                });
              }
            }}
          >
            CONFIRM
          </Button>
        ),
      });
      return;
    }
    setSnackbarOpen({
      type: "error",
      message: "Are you sure you want to delete this news instance?",
      action: (
        <Button
          color="inherit"
          size="small"
          onClick={async () => {
            setSnackbarOpen(null);
            try {
              await DataStore.delete(Post, id);
              setSnackbarOpen({
                type: "success",
                message: "Successfully deleted news instance!",
              });
            } catch (err) {
              setSnackbarOpen({
                type: "error",
                message: "Unexpected error deleting news",
              });
            }
          }}
        >
          CONFIRM
        </Button>
      ),
    });

    setReRender((old) => !old);
  };

  return (
    <div
      className={`module-container news ${link ? "news-link" : ""}`}
      onClick={() => window.open(link, "_blank", "noreferrer")}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: "3%",
      }}
    >
      {image && (
        <img
          src={require(`../assets/${image}`)}
          style={{
            width: "8em",
            height: "8em",

            borderRadius: "100%",
          }}
        />
      )}

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "flex-start",
          height: "100%",
        }}
      >
        {title && (
          <span
            style={{
              ...styles.defaultText,
              fontSize: "1.2em",
              textAlign: "left",
              color: "black",
              fontFamily: "TisaSansPro-Bold",
            }}
          >
            {title}
          </span>
        )}
        {description && (
          <span
            style={{ ...styles.defaultText, color: "black", textAlign: "left" }}
          >
            {description}
          </span>
        )}

        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
          {date && (
            <span style={{ ...styles.defaultText, color: "black" }}>
              {date}
            </span>
          )}
        </div>
      </div>
      {dbUser?.access === "admin" ? (
        <div style={{ display: "flex" }}>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleSwap("increase");
              }}
              size="small"
            >
              <ArrowDropUp sx={{ fontSize: "1.75em" }} />
            </IconButton>

            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleSwap("decrease");
              }}
              size="small"
            >
              <ArrowDropDown sx={{ fontSize: "1.75em" }} />
            </IconButton>
          </div>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
          >
            <Delete size="large" color="error" />
          </IconButton>
        </div>
      ) : dbUser?.access === "admin" ? (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
        >
          <Delete size="large" color="error" />
        </IconButton>
      ) : null}
    </div>
  );
};

const styles = {
  defaultText: {
    fontFamily: "TisaSansPro",
    color: colors.primaryColor,
  },
  headerText: {
    fontSize: "3em",
    color: colors.primaryColor,
    marginBottom: ".5em",
  },
};

export default NewsModule;
