import { FacebookTwoTone, Instagram, Twitter } from "@mui/icons-material";
import { Card, CardContent, Grid, Typography, Button } from "@mui/material";
import { FaGoodreadsG } from "react-icons/fa";
import { colors } from "../../theme/colors";
import "./ContactModule.css";

const ContactModule = ({
  name,
  company,
  role,
  link,
  isOriginalAuthor,
  subText,
  subTextLink,
}) => {
  return (
    <div
      style={{
        textAlign: "left",
        display: "flex",
        padding: 0,

        margin: 0,
        flexWrap: "wrap",
      }}
    >
      <div
        className={`highlight-div ${isOriginalAuthor ? "original-author" : ""}`}
        style={{
          backgroundColor: isOriginalAuthor
            ? `${colors.primaryColor}22`
            : `${colors.secondaryColor}20`,
          minWidth: !isOriginalAuthor ? "50%" : undefined,
          marginRight: "auto",
          width: "100%",
          marginLeft: !isOriginalAuthor ? "auto" : undefined,
          textAlign: !isOriginalAuthor ? "center" : "left",
        }}
      >
        {isOriginalAuthor ? (
          <h1
            style={{
              fontSize: "3em",
            }}
          >
            Contact Angie
          </h1>
        ) : (
          <h2 style={{ fontSize: "1.5em" }}>
            {name}, {role}&nbsp;&nbsp;
          </h2>
        )}

        {company && (
          <p style={{ fontSize: "1.2em", marginBottom: "1em" }}>{company}</p>
        )}
        <p
          className="email"
          style={{
            marginTop: isOriginalAuthor ? "-.75em" : "0",
            color: isOriginalAuthor
              ? colors.primaryColor
              : colors.secondaryColor,
            fontSize: isOriginalAuthor ? "1.3em" : "1em",
          }}
          onClick={(e) => {
            window.location.href = `mailto:${link}`;
          }}
        >
          {link}
        </p>
        {subText ? (
          <Typography
            variant="p"
            sx={{
              mt: 1,
              color: colors.secondaryColor,
              cursor: "pointer",
              ":hover": {
                opacity: 0.7,
              },
              transition: "opacity 500ms ease-in-out",
            }}
            onClick={(e) => {
              window.open(subTextLink, "_blank", "noreferrer");
            }}
          >
            {subText}
          </Typography>
        ) : null}
        {isOriginalAuthor ? (
          <div style={{ marginTop: "2em", height: "100%" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                window.open(
                  "https://angiekimbooks.us19.list-manage.com/subscribe?u=7b4153539cf22a4390483e970&id=a2d1eee013",
                  "_blank",
                  "noreferrer"
                );
              }}
            >
              SIGN UP FOR NEWSLETTER
            </Button>
            <Grid container xs={12} style={{ marginTop: "2em" }}>
              <Grid item xs={3}>
                <FacebookTwoTone
                  className="icon"
                  color="primary"
                  sx={{ fontSize: "3em" }}
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/angie.kim.va",
                      "_blank",
                      "noreferrer"
                    )
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Instagram
                  className="icon"
                  color="primary"
                  sx={{ fontSize: "3em" }}
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/angiekimask/",
                      "_blank",
                      "noreferrer"
                    )
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Twitter
                  className="icon"
                  color="primary"
                  sx={{ fontSize: "3em" }}
                  onClick={() =>
                    window.open(
                      "https://twitter.com/AngieKimWriter",
                      "_blank",
                      "noreferrer"
                    )
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <FaGoodreadsG
                  size={"2.5em"}
                  style={{ marginTop: ".25em" }}
                  className="icon"
                  color={colors.primaryColor}
                  onClick={() =>
                    window.open(
                      "https://www.goodreads.com/book/show/40121959-miracle-creek",
                      "_blank",
                      "noreferrer"
                    )
                  }
                />
              </Grid>
            </Grid>
          </div>
        ) : null}
      </div>
      {/* {isOriginalAuthor ? (
          <img
            className="author-image"
            style={{
              borderRadius: "5px",
            }}
            src={require("../../assets/AuthorPhoto.jpg")}
          />
        ) : null} */}
    </div>
  );
};

export default ContactModule;
