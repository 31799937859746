import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Snackbar,
  TextField,
} from "@mui/material";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { DataStore, SortDirection } from "aws-amplify";
import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";

import NewsModule from "../../components/NewsModule";
import PublicHeader from "../../components/PublicHeader";
import { useAuthContext } from "../../contexts/AuthContext";
import { Event, Text } from "../../models";
import { colors } from "../../theme/colors";
import "./Events.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Events = () => {
  const [pastEvents, setPastEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [loadedIn, setLoadedIn] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [unfilteredEvents, setUnfilteredEvents] = useState([]);
  const [editingModalOpen, setEditingModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [currentlyEditing, setCurrentlyEditing] = useState(null);
  const [reRender, setReRender] = useState(false);
  const { dbUser } = useAuthContext();

  const [newEventDate, setNewEventDate] = useState(null);

  useEffect(() => {
    DataStore.observeQuery(Event, (e) => e.msDate.lt(Date.now()), {
      sort: (e) => e.featured(SortDirection.ASCENDING),
    }).subscribe((res) => {
      let toBeDeleted = res.items.filter((e) => e.type !== "highlighted");
      if (toBeDeleted?.length) {
        toBeDeleted.forEach((event) => {
          DataStore.delete(Event, event.id);
        });
      }
      setPastEvents(res.items.filter((e) => e.type === "highlighted"));
    });
    DataStore.observeQuery(Event, (e) => e.msDate.gt(Date.now()), {
      sort: (e) => e.msDate(SortDirection.ASCENDING),
    }).subscribe((res) => setUpcomingEvents(res.items));
    DataStore.observeQuery(Text, (t) => t.type.beginsWith("events/")).subscribe(
      (res) => {
        setUnfilteredEvents(
          res.items.sort((e, f) =>
            e.type
              .substring(e.type.indexOf("/"))
              .localeCompare(f.type.substring(f.type.indexOf("/")))
          )
        );
      }
    );
    setLoadedIn(true);
  }, []);

  const createNewEvent = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    let msDate = Math.floor(new Date(newEventDate?.$d)).toString();

    if (!msDate) {
      alert("Invalid date, please enter date in MM/DD/YYYY format");
    }

    if (formData.get("highlighted")) {
      pastEvents.map((p) => {
        DataStore.save(
          Event.copyOf(p, (updated) => {
            updated.featured = p.featured + 1;
          })
        );
      });
    }
    DataStore.save(
      new Event({
        title: formData.get("title"),
        location: formData.get("location"),
        link: formData.get("link"),
        details: formData.get("details"),
        date: formData.get("date-string"),
        type: formData.get("highlighted") ? "highlighted" : null,
        msDate: Math.floor(new Date(newEventDate?.$d)).toString(),
        featured: formData.get("highlighted") ? 0 : null,
      })
    );
    setModalOpen(false);
  };

  const saveNews = async () => {
    let isChanged = false;
    for (let i = 0; i < pastEvents.length; i++) {
      await DataStore.query(Event, pastEvents[i].id).then((post) => {
        if (post.featured !== i) {
          isChanged = true;
          DataStore.save(
            Event.copyOf(post, (updated) => {
              updated.featured = i;
            })
          );
        }
      });
    }
    isChanged
      ? alert("New order saved")
      : alert("Please make changes before saving a new order");
  };

  const editText = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    DataStore.query(Text, currentlyEditing.id).then((res) =>
      DataStore.save(
        Text.copyOf(res, (updated) => {
          updated.body = formData.get("body");
        })
      )
    );

    setEditingModalOpen(false);
    setCurrentlyEditing(null);
  };

  const openEditingDialog = (text) => {
    setCurrentlyEditing(text);
    setEditingModalOpen(true);
  };

  return (
    <div className={`container ${loadedIn ? "loadedIn" : ""}`}>
      <PublicHeader currentRoute={"/events"} />
      <div
        style={{
          paddingTop: "7em",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Snackbar open={snackbarOpen} autoHideDuration={6000}>
          <Alert
            onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
            action={snackbarOpen?.action ? snackbarOpen?.action : null}
            severity={snackbarOpen?.type}
            sx={{
              width: "100%",
              fontSize: "1.2em",
              display: "flex",
              alignItems: "center",
            }}
          >
            {snackbarOpen?.message}
          </Alert>
        </Snackbar>
        <div
          className="dynamic-width"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "7em",
            alignItems: "center",
          }}
        >
          <h1 style={{ ...styles.headerText, marginBottom: 0 }}>
            Upcoming Events
          </h1>
          <p
            style={{
              ...styles.headerText,
              fontFamily: "TisaSansPro-Bold",
              fontSize: "1.5em",
              color: "black",
              marginTop: "-.75em",
            }}
          >
            *Click for more information.
          </p>

          {dbUser?.access === "admin" ? (
            <div style={{ display: "flex", gap: "1em" }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{
                  fontFamily: "TisaSansPro-Bold",
                  marginBottom: "2em",
                }}
                onClick={() => setModalOpen(true)}
              >
                Add new event
              </Button>
              <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box
                  component="form"
                  onSubmit={createNewEvent}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "white",
                    borderRadius: "12px",
                    boxShadow: 24,
                    p: 4,
                    gap: "1em",
                    minWidth: "40dvw",
                  }}
                >
                  <h1 style={{ fontSize: "2.5em", color: colors.primaryColor }}>
                    Create Event
                  </h1>
                  <TextField
                    name="title"
                    label="title"
                    required
                    color="secondary"
                  />
                  <TextField
                    name="location"
                    label="location (enter 'Virtual' for virtual)"
                    color="secondary"
                  />
                  <TextField name="details" label="details" color="secondary" />
                  <TextField name="link" label="link" color="secondary" />

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      name="date"
                      required
                      label="date (purely for ordering)"
                      inputFormat="MM/dd/yyyy"
                      onChange={(value) => setNewEventDate(value)}
                    />
                  </LocalizationProvider>
                  <TextField
                    name="date-string"
                    label="date (for display)"
                    color="secondary"
                  ></TextField>
                  <FormControlLabel
                    control={<Checkbox name="highlighted" />}
                    label="Highlighted"
                  />
                  <Button type="submit">Create</Button>
                </Box>
              </Modal>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                style={{
                  fontFamily: "TisaSansPro-Bold",
                  marginBottom: "2em",
                }}
                onClick={saveNews}
              >
                Save Changes
              </Button>
            </div>
          ) : null}

          <div
            style={{
              gap: ".5em",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {upcomingEvents.length ? (
              upcomingEvents.map((event) => (
                <NewsModule
                  date={event.date}
                  title={event.title}
                  description={`${event.location && event.location} ${
                    event.details ? " • " + event.details : ""
                  }`}
                  link={event.link}
                  linkTitle="See More"
                  type="event"
                  id={event.id}
                  setSnackbarOpen={setSnackbarOpen}
                />
              ))
            ) : (
              <p style={{ fontSize: "1.6em" }}>Coming soon!</p>
            )}
          </div>
        </div>
        {pastEvents?.filter((p) => p.type === "highlighted").length ? (
          <div
            className="sectionContainer"
            style={{
              paddingBottom: "7em",
              justifyContent: "flex-start",
              flexDirection: "column",
              gap: ".5em",
            }}
          >
            <h1 style={{ ...styles.headerText, marginBottom: 0 }}>
              {"highlighted recorded events"}
            </h1>

            <p
              style={{
                ...styles.headerText,
                fontFamily: "TisaSansPro-Bold",
                fontSize: "1.5em",
                color: "black",
                marginTop: "-1em",
              }}
            >
              A sampling of different types of events. Click on any event
              listing to watch.
            </p>

            {pastEvents
              .filter((p) => p.type === "highlighted")
              .map((event, index) => {
                return (
                  <NewsModule
                    setLocal={setPastEvents}
                    setReRender={setReRender}
                    date={event.date}
                    title={event.title}
                    description={`${event.location ? event.location : ""} ${
                      event.details ? " • " + event.details : ""
                    }`}
                    link={event.link}
                    linkTitle="See More"
                    type="event"
                    id={event.id}
                    index={index}
                    setSnackbarOpen={setSnackbarOpen}
                  />
                );
              })}
          </div>
        ) : null}
        <h1 style={{ ...styles.headerText, marginBottom: 0 }}>
          {"Other Past Events"}
        </h1>
        <p
          style={{
            ...styles.headerText,
            fontFamily: "TisaSansPro-Bold",
            fontSize: "1.5em",
            color: "black",
            marginTop: "-.75em",
          }}
        >
          * denotes a virtual event.
        </p>
        <Grid
          container
          className="dynamic-width"
          rowGap={2}
          style={{
            paddingBottom: "7em",
            justifyContent: "space-between",
          }}
        >
          {dbUser?.access === "admin" && currentlyEditing && (
            <Modal
              open={editingModalOpen}
              onClose={() => {
                setEditingModalOpen(false);
                setCurrentlyEditing(null);
              }}
            >
              <Box
                component="form"
                onSubmit={editText}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  borderRadius: "12px",
                  boxShadow: 24,
                  p: 4,
                  gap: "1em",
                  minWidth: "40dvw",
                }}
              >
                <h1
                  style={{
                    fontSize: "2.5em",
                    color: colors.primaryColor,
                    margin: 0,
                  }}
                >
                  Edit{" "}
                  {currentlyEditing.type.substring(
                    currentlyEditing.type.indexOf("/") + 1
                  )}
                </h1>
                <p
                  style={{
                    ...styles.headerText,
                    fontFamily: "TisaSansPro-Bold",
                    fontSize: "1.5em",
                    color: "black",
                    marginTop: "-.75em",
                  }}
                >
                  Add | between each event entry (look at past events for
                  examples)
                </p>
                <TextField
                  name="body"
                  label="body"
                  required
                  color="secondary"
                  multiline
                  defaultValue={currentlyEditing.body}
                />

                <Button type="submit">Save</Button>
              </Box>
            </Modal>
          )}
          {unfilteredEvents.map((text) => {
            return (
              <>
                <Grid
                  sx={{
                    flexDirection: "column",
                    borderRadius: "16px",
                    gap: ".15em",
                    backgroundColor: `${colors.secondaryColor}20`,
                    justifyContent: "flex-start",
                    padding: "2em",
                  }}
                  item
                  xs={12}
                  lg={5.9}
                  className={`sectionContainer ${
                    dbUser?.access === "admin" && "editable"
                  }`}
                  onClick={() => openEditingDialog(text)}
                >
                  <h1 style={{ ...styles.headerText }}>
                    {text.type.substring(text.type.indexOf("/") + 1)}
                  </h1>
                  {text.body.split("|").map((event) => (
                    <p style={{ textAlign: "left", fontSize: "1.2em" }}>
                      {event}
                    </p>
                  ))}
                </Grid>
              </>
            );
          })}
        </Grid>
      </div>

      <Footer />
    </div>
  );
};

const styles = {
  defaultText: {
    fontFamily: "TisaSansPro",
    color: colors.primaryColor,
  },
  headerText: {
    fontSize: "3em",
    color: colors.primaryColor,
    marginBottom: ".5em",
  },
};

export default Events;
