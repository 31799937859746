import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import { DataStore } from "aws-amplify";
import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import PublicHeader from "../../components/PublicHeader";
import { useAuthContext } from "../../contexts/AuthContext";
import { Text } from "../../models";
import { colors } from "../../theme/colors";

const OtherWork = () => {
  const [loadedIn, setLoadedIn] = useState(false);
  const [works, setWorks] = useState([]);
  const [editingModalOpen, setEditingModalOpen] = useState(false);
  const [currentlyEditing, setCurrentlyEditing] = useState(null);
  const { dbUser } = useAuthContext();

  useEffect(() => {
    DataStore.query(Text, (t) => t.type.beginsWith("other-work/")).then(
      setWorks
    );
    setLoadedIn(true);
  }, []);

  const editText = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    DataStore.query(Text, currentlyEditing.id).then((res) =>
      DataStore.save(
        Text.copyOf(res, (updated) => {
          updated.body = formData.get("body");
        })
      )
    );

    setEditingModalOpen(false);
    setCurrentlyEditing(null);
  };

  const openEditingDialog = (text) => {
    setCurrentlyEditing(text);
    setEditingModalOpen(true);
  };

  return (
    <div className={`container ${loadedIn ? "loadedIn" : ""}`}>
      <PublicHeader currentRoute={"/other-work"} />
      <div
        style={{
          paddingTop: "7em",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          className="dynamic-width"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "7em",
            alignItems: "center",
          }}
        >
          <Grid
            container
            className="dynamic-width"
            rowGap={2}
            style={{
              paddingBottom: "7em",
              justifyContent: "space-between",
            }}
          >
            {dbUser?.access === "admin" && currentlyEditing && (
              <Modal
                open={editingModalOpen}
                onClose={() => {
                  setEditingModalOpen(false);
                  setCurrentlyEditing(null);
                }}
              >
                <Box
                  component="form"
                  onSubmit={editText}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "white",
                    borderRadius: "12px",
                    boxShadow: 24,
                    p: 4,
                    gap: "1em",
                    minWidth: "40dvw",
                  }}
                >
                  <h1
                    style={{
                      fontSize: "2.5em",
                      color: colors.primaryColor,
                      margin: 0,
                    }}
                  >
                    Edit{" "}
                    {currentlyEditing.type.substring(
                      currentlyEditing.type.indexOf("/") + 1
                    )}
                  </h1>
                  <p
                    style={{
                      ...styles.headerText,
                      fontFamily: "TisaSansPro-Bold",
                      fontSize: "1.5em",
                      color: "black",
                      marginTop: "-.75em",
                    }}
                  >
                    {`Write your new events in the following format:
                   Event Source <LinkTitle> [Link] (description), using | as a delimeter between events without spaces around the |, or follow the format seen in the existing body`}
                  </p>
                  <TextField
                    name="body"
                    label="body"
                    required
                    color="secondary"
                    multiline
                    defaultValue={currentlyEditing.body}
                  />

                  <Button type="submit">Save</Button>
                </Box>
              </Modal>
            )}
            {works.map((text) => {
              return (
                <>
                  <Grid
                    sx={{
                      flexDirection: "column",
                      borderRadius: "16px",
                      gap: ".15em",
                      backgroundColor: `${colors.secondaryColor}20`,
                      justifyContent: "flex-start",
                      padding: "2em",
                    }}
                    item
                    xs={12}
                    lg={12}
                    className={`sectionContainer ${
                      dbUser?.access === "admin" && "editable"
                    }`}
                    onClick={() => openEditingDialog(text)}
                  >
                    <h1 style={{ ...styles.headerText }}>
                      Selected {text.type.substring(text.type.indexOf("/") + 1)}
                    </h1>
                    {text.body.split("|").map((body) => {
                      const isLinked = body.indexOf("<") !== -1;
                      const linkTitle = body.substring(
                        body.indexOf("<") + 1,
                        body.indexOf(">")
                      );
                      const link = body.substring(
                        body.indexOf("[") + 1,
                        body.indexOf("]")
                      );
                      const source = body.substring(0, body.indexOf("<"));
                      const description = body.substring(body.indexOf("]") + 1);
                      return (
                        <p style={{ textAlign: "left", fontSize: "1.5em", marginBottom: '.3em' }}>
                          {isLinked ? `${source}` : body}
                          {isLinked && (
                            <a
                              href={link}
                              target="_blank"
                              style={{ opacity: 0.6 }}
                            >
                              {linkTitle}
                            </a>
                          )}
                          {isLinked && description}
                        </p>
                      );
                    })}
                  </Grid>
                </>
              );
            })}
          </Grid>
        </div>
        <Footer />
      </div>
    </div>
  );
};

const styles = {
  defaultText: {
    fontFamily: "TisaSansPro",
    color: colors.primaryColor,
  },
  headerText: {
    fontSize: "3em",
    color: colors.primaryColor,
    marginBottom: ".5em",
  },
};

export default OtherWork;
