import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { Auth } from "aws-amplify"
import { useNavigate } from "react-router-dom"

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false)
  const [emailError, setEmailError] = React.useState(null)

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let error = false;
    if (data.get("email") === "") {
      setEmailError({ message: "Email cannot be empty" });
      error = true;
    } else if (
      !data.get("email").includes("@") ||
      !data.get("email").includes(".")
    ) {
      setEmailError({ message: "Email not valid" });
      error = true;
    } else {
      setEmailError(null);
    }
    if (error) {
      setLoading(false);
      return;
    }


    Auth.forgotPassword(data.get('email'))
    .then(() => {
      navigate('/reset/' + data.get('email'))
    })
    .catch(err => {
      setLoading(false)
      setEmailError({ message: err.message })
    })
  }

  return (
      <Container component="main" maxWidth="xs" >
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              color="secondary"
              margin="normal"
              required
              fullWidth
              sx={{minWidth: "400px",}}
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              inputProps={{autoCapitalize: "none"}}
              error = {emailError ? true : false}
              helperText = {emailError ? emailError.message : null}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ mt: 3, mb: 2, color: "white", fontWeight: "bold", }}
            >
              {loading ? 'Sending...' : 'Send Code'}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link color={'#1e5262'} href="/login" variant="body2">
                  Back to Login
                </Link>
              </Grid>
              <Grid item>
                <Link color={'#1e5262'} href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
  );
}

export default ForgotPassword;