import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


import { Auth } from "aws-amplify"
import { useNavigate, useParams } from "react-router-dom"

const ConfirmSignUp = () => {
  const navigate = useNavigate();

  const {username} = useParams()
  
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)

  const handleConfirm = async (event) => {
    setLoading(true)
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    if(!username) {
      return
    }
      try {
        await Auth.confirmSignUp(username, data.get('code'))
        navigate('/login')
      }
      catch (err) {
        setError(err)
        setLoading(false)
        console.log(err)
      }
    }

  return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Confirm Email
          </Typography>
          <Box component="form" onSubmit={handleConfirm} noValidate sx={{ mt: 1 }}>
            <TextField
            defaultValue={username}
              contentEditable={false}
              color="secondary"
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              disabled
              autoFocus
            />
            <TextField
              color="secondary"
              margin="normal"
              required
              fullWidth
              name="code"
              label="Verification Code"
              id="code"
              autoComplete="current-password"
              error={error}
              helperText={error ? error.message : null}
              
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ mt: 3, mb: 2, color: "white", fontWeight: "bold", }}
            >
              {loading ? 'Loading...' : 'Confirm'}
            </Button>
            <Grid container justifyContent="flex-end">
            <Grid item>
              <Link color="secondary" href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
          </Box>
        </Box>
      </Container>
  );
}

export default ConfirmSignUp;