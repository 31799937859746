import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
} from "@mui/material";
import { DataStore, Predicates, SortDirection } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomImageList from "../../components/CustomImageList";
import Footer from "../../components/Footer";

import NewsModule from "../../components/NewsModule";
import PublicHeader from "../../components/PublicHeader";
import ReviewModule from "../../components/ReviewModule";
import { useAuthContext } from "../../contexts/AuthContext";
import { Post } from "../../models";
import { colors } from "../../theme/colors";
import "./News.css";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loadedIn, setLoadedIn] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { dbUser } = useAuthContext();
  const [reRender, setReRender] = useState(false);

  useEffect(() => {
    const reviewSubscription = DataStore.observeQuery(Post, (p) => p.type.eq("REVIEW"), {
      sort: (p) => p.featured(SortDirection.ASCENDING),
    }).subscribe((res) => setReviews(res.items));
    setLoadedIn(true);

    return () => reviewSubscription.unsubscribe();
  }, []);
  const createNewReview = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    if(formData.get("featured")) {
      reviews.map(review => {
        DataStore.save(Post.copyOf(review, updated => {
          updated.featured = review.featured + 1;
        }))
      })
    }
    DataStore.save(
      new Post({
        type: "REVIEW",
        title: formData.get("title"),
        body: formData.get("review"),
        link: formData.get("link"),
        linkText: formData.get("author"),
        featured: formData.get("featured") ? 0 : reviews.length,
      })
    );

    
  };

  const saveReviews = async () => {
    let isChanged = false;
    for (let i = 0; i < reviews.length; i++) {
      await DataStore.query(Post, reviews[i].id).then((post) => {
        if (post.featured !== i) {
          isChanged = true;
          DataStore.save(
            Post.copyOf(post, (updated) => {
              updated.featured = i;
            })
          );
        }
      });
    }
    isChanged
      ? alert("New order saved")
      : alert("Please make changes before saving a new order");
  };

  return (
    <div className={`container ${loadedIn ? "loadedIn" : ""}`}>
      <PublicHeader currentRoute={"/news"} />
      <div
        style={{
          paddingTop: "7em",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 style={{ ...styles.headerText }}>Reviews</h1>
        {dbUser?.access === "admin" ? (
          <React.Fragment>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{
                fontFamily: "TisaSansPro-Bold",
              }}
              onClick={() => setModalOpen(true)}
            >
              Add new review
            </Button>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
              <Box
                component="form"
                onSubmit={createNewReview}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  borderRadius: "12px",
                  boxShadow: 24,
                  p: 4,
                  gap: "1em",
                  minWidth: "40dvw",
                }}
              >
                <h1 style={{ fontSize: "2.5em", color: colors.primaryColor }}>
                  Create Review
                </h1>
                <TextField
                  name="title"
                  label="title"
                  required
                  color="secondary"
                />
                <TextField
                  name="review"
                  label="review text"
                  required
                  color="secondary"
                />
                <TextField
                  name="author"
                  label="author"
                  required
                  color="secondary"
                />
                <TextField name="link" label="link" color="secondary" />
                <FormControlLabel
                  label="Push to Top"
                  name="featured"
                  control={<Checkbox value="featured" color="secondary" />}
                ></FormControlLabel>
                <Button type="submit">Create</Button>
              </Box>
            </Modal>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{
                fontFamily: "TisaSansPro-Bold",
                marginTop: ".5em",
                marginBottom: "1em",
              }}
              onClick={saveReviews}
            >
              Save New Order
            </Button>
          </React.Fragment>
        ) : null}
        <div
          className="sectionContainer"
          style={{
            justifyContent: "flex-start",
            flexDirection: "column",
            gap: ".75em",
            paddingBottom: "7em",
          }}
        >
          {reviews.map((review, index) => {
            return (
              <ReviewModule
              setReRender={setReRender}
              setReviews={setReviews}
                title={review.title}
                body={review.body}
                name={review.linkText}
                index = {index}
              />
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
};

const styles = {
  defaultText: {
    fontFamily: "TisaSansPro",
    color: colors.primaryColor,
  },
  headerText: {
    fontSize: "3em",
    color: colors.primaryColor,
  },
};

export default Reviews;
