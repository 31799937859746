import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Auth, DataStore, Hub } from "aws-amplify";
import { User } from "../models";

const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [dbUser, setDbUser] = useState(null);

  const checkUser = async () => {
    try {
      const tempAuthUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      setAuthUser(tempAuthUser);
    } catch (e) {
      setAuthUser(null);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  const sub = authUser?.attributes?.sub;

  useEffect(() => {
    if (sub) {
      const userSubscription = DataStore.observeQuery(User, (s) =>
        s.sub.eq(sub)
      ).subscribe(async (users) => {
        setDbUser(users.items[0]);
      });

      return () => userSubscription.unsubscribe();
    }
  }, [sub]);

  useEffect(() => {
    const listener = async (data) => {
      if (
        data?.payload?.event === "signIn" ||
        data?.payload?.event === "signOut"
      ) {
        checkUser();
      }
    };

    Hub.listen("auth", listener);
    return () => listener(); /*To avoid memory leaks*/
  }, []);

  return (
    <AuthContext.Provider
      value={{
        dbUser,
        authUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

export const useAuthContext = () => useContext(AuthContext);
