import { Box, Typography } from "@mui/material";
import { colors } from "../../theme/colors";
import PublicHeader from "../../components/PublicHeader";
import Footer from "../../components/Footer";

const FurtherResources = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <PublicHeader currentRoute={"/books"} />
      <Box
        sx={{
          pt: "7em",
          alignSelf: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 0,
          justifyContent: "flex-start",
          height: '100dvh'
        }}
        className="sectionContainer"
      >
        <Typography variant="h1" sx={{ margin: 0 }}>
          Further Resources
        </Typography>
        <p
          variant="p"
          style={{
            opacity: 0.6,
            fontSize: "1.2em",
            lineHeight: "110%",
            marginBlock: 0,
            marginBottom: "2em",
          }}
        >
          I found the resources listed below to be very helpful in conducting my
          research around Happiness Falls. This list is for informational value
          only and the inclusion of a specific resource does not indicate my
          endorsement. Likewise, the presence of an organization or person(s) in
          this list does not in any way mean that organization or person(s)
          endorses Happiness Falls.
        </p>
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: 1, mb: "auto" }}
        >
          <Box className="module-container news">
            <span
              style={{
                fontSize: "1.2em",
                textAlign: "left",
                color: "black",
                fontFamily: "TisaSansPro-Bold",
                marginBottom: 5,
              }}
            >
              <a
                className="link"
                style={{
                  margin: 0,
                  padding: 0,
                  cursor: "pointer",
                  color: colors.secondaryColor,
                }}
                onClick={() =>
                  window.open("https://i-asc.org/", "_blank", "noreferrer")
                }
              >
                International Association for Spelling as a Communication
              </a>{" "}
              (I-ASC)
            </span>
            <br />
            <span
              style={{
                fontSize: "1",
                textAlign: "left",
                fontFamily: "TisaSansPro",
              }}
            >
              A leading nonprofit advocacy organization serving nonspeakers and
              the S2C (spelling to communicate) method. Co-located and
              affiliated with the Growing Kids Therapy Center , a therapy center
              in northern Virginia dedicated to teaching nonspeakers how to
              spell to communicate (S2C). This is a special organization to me
              personally, as I teach creative writing (in virtual and in-person
              sessions) to nonspeakers at this organization.
            </span>
          </Box>
          <Box className="module-container news">
            <span
              style={{
                fontSize: "1.2em",
                textAlign: "left",
                color: "black",
                fontFamily: "TisaSansPro-Bold",
                marginBottom: 5,
              }}
            >
              <a
                className="link"
                style={{
                  margin: 0,
                  padding: 0,
                  cursor: "pointer",
                  color: colors.secondaryColor,
                }}
                onClick={() =>
                  window.open("https://jaswallab.org/", "_blank", "noreferrer")
                }
              >
                Jaswal Lab
              </a>
            </span>
            <br />
            <span
              style={{
                fontSize: "1",
                textAlign: "left",
                fontFamily: "TisaSansPro",
              }}
            >
              A lab affiliated with the Department of Psychology at the
              University of Virginia, with the mission of conducting autism
              research inspired and informed by autistic peope, their families,
              and the community. Headed by Dr. Vikram Jaswal, Professor of
              Psychology at UVa and Editor in Chief of the Journal of Cognition
              and Development.
            </span>
          </Box>
          <Box className="module-container news">
            <span
              style={{
                fontSize: "1.2em",
                textAlign: "left",
                color: "black",
                fontFamily: "TisaSansPro-Bold",
                marginBottom: 5,
              }}
            >
              <a
                className="link"
                style={{
                  margin: 0,
                  padding: 0,
                  cursor: "pointer",
                  color: colors.secondaryColor,
                }}
                onClick={() =>
                  window.open(
                    "https://www.angelman.org/",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                Angelman Syndrome Foundation
              </a>
            </span>
            <br />
            <span
              style={{
                fontSize: "1",
                textAlign: "left",
                fontFamily: "TisaSansPro",
              }}
            >
              An organization with the mission to advance the awareness and
              treatment of Angelman syndrome through education, research, and
              support for those with Angelman syndrome and their families.
            </span>
          </Box>
        </Box>
        <Footer />
      </Box>
    </div>
  );
};

export default FurtherResources;
