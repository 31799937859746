import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { Auth } from "aws-amplify"
import { useNavigate, useParams } from "react-router-dom"

const ResetPassword = () => {
  const navigate = useNavigate();

  const {username } = useParams()

  const [loading, setLoading] = React.useState(false)
  const [codeError, setCodeError] = React.useState(null)
  const [passwordError, setPasswordError] = React.useState(null)

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let error = false;
    if(data.get('code') === "") {
        setCodeError({ message: "Code cannot be empty" });
        error = true;
    } else if (data.get('code').length < 6) {
        setCodeError({ message: "Code must be at least 6 characters" });
        error = true;
    }
    else {
        setCodeError(null);
    }
    if (data.get("password") === "") {
      setPasswordError({ message: "Password cannot be empty" });
      error = true;
    } else if (data.get("password").length < 8) {
      setPasswordError({ message: "Password must be at least 8 characters" });
      error = true;
    } else if (data.get("password").includes(" ")) {
      setPasswordError({ message: "Password cannot contain spaces" });
      error = true;
    }
    else {
      setPasswordError(null);
    }
    if (error) {
      setLoading(false);
      return;
    }

    Auth.forgotPasswordSubmit(username, data.get('code'), data.get('password'))
    .then((data) => {
        console.log(data)
        navigate('/login')
    })
    
    .catch (err => {
      setLoading(false)
      console.log(err)
      if(err.message.toLowerCase().includes('code')) {
        setCodeError({ message: err.message });
        }
      else if (
        err.message.toLowerCase().includes("username")
      ) {
        setCodeError({ message: "Email not valid" });
      } 
      if(err.message.toLowerCase().includes("password")){
        setPasswordError({ message: "Password not valid" });
      }

      if(err.message === "Attempt limit exceeded, please try after some time.") {
        setCodeError({ message: err.message });
        setPasswordError({ message: err.message });
      }

    })
  }

  return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              color="secondary"
              margin="normal"
              required
              fullWidth
              id="code"
              label="Code"
              name="code"
              autoFocus
              error = {codeError ? true : false}
              helperText = {codeError ? codeError.message : null}
            />
            <TextField
              color="secondary"
              margin="normal"
              required
              fullWidth
              name="password"
              label="New Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error = {passwordError ? true : false}
              helperText = {passwordError ? passwordError.message : null}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ mt: 3, mb: 2, color: "white", fontWeight: "bold", }}
            >
              {loading ? 'Loading...' : 'Reset Password'}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link color={'#1e5262'} href="/login" variant="body2">
                  Back to Login
                </Link>
              </Grid>
              <Grid item>
                <Link color={'#1e5262'} href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
  );
}

export default ResetPassword;