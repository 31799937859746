import apple from "./apple.png";
import amazon from "./amazon.png";
import barnesandnoble from "./barnesandnoble.png";
import booksamillion from "./booksamillion.png";
import bookshop from "./bookshop.png";
import walmart from "./walmart.png";
import audiobooks from "./audiobooks.png";
import target from "./target.png";
import scrawlBooks from "./ScrawlBooks.png";
import prh from "./prh.png";
export const happiness_falls_retailers = [
  { image: scrawlBooks, link: "https://www.scrawlbooks.com/angie-kim" },
  {
    image: amazon,
    link: "https://www.amazon.com/gp/product/0593448200?tag=randohouseinc7986-20",
  },
  {
    image: apple,
    link: "https://books.apple.com/us/book/happiness-falls/id6445344470",
  },
  {
    image: barnesandnoble,
    link: "https://www.barnesandnoble.com/w/happiness-falls-angie-kim/1142888527;jsessionid=A920E9DDA8DED9540EBA763561F291C8.prodny_store01-atgap10?ean=9780593448205&st=AFF&2sid=Random%20House%20Inc_8373827_NA&sourceId=AFFRandom%20House%20Inc",
  },
  {
    image: booksamillion,
    link: "https://www.booksamillion.com/p/9780593448205?AID=10747236&PID=8373827&SID=PRHEFFDF5A7F1--9780593448205&cjevent=9d9dcbcfc70511ed80882e980a82b82d&cjdata=MXxOfDB8WXww",
  },
  {
    image: bookshop,
    link: "https://bookshop.org/p/books/happiness-falls-angie-kim/19598438?ean=9780593448205",
  },
  {
    image: target,
    link: "https://www.target.com/s?searchTerm=9780593448205&clkid=1490206bN9db311ed9cec23181fb08f49&lnm=81938&afid=Penguin%20Random%20House&ref=tgt_adv_xasd0002",
  },
  {
    image: walmart,
    link: "https://www.walmart.com/ip/Happiness-Falls-Hardcover-9780593448205/1505970653",
  },
  // { image: audiobooks, link: "https://audiobookstore.com/" },
  {
    image: prh,
    link: "https://www.penguinrandomhouse.com/books/690563/happiness-falls-by-angie-kim/",
  },
];

export const miracle_creek_retailers = [
  {
    image: amazon,
    link: "https://www.amazon.com/Miracle-Creek-Novel-Angie-Kim/dp/1250251303/",
  },

  {
    image: barnesandnoble,
    link: "https://www.barnesandnoble.com/w/miracle-creek-angie-kim/1129535163",
  },
  {
    image: bookshop,
    link: "https://bookshop.org/p/books/miracle-creek-angie-kim/6985665",
  },

  {
    image: apple,
    link: "https://books.apple.com/us/book/miracle-creek/id1387497627",
  },

  {
    image: booksamillion,
    link: "https://www.booksamillion.com/p/Miracle-Creek/Angie-Kim/9781250251305?id=8834909775368",
  },
];
