import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Landing from "../Landing";
import { colors } from "../../theme/colors";
import Bio from "../bio/Bio";
import News from "../news/News";

import Login from "../auth/Login";
import ConfirmSignUp from "../auth/ConfirmSignUp";
import ForgotPassword from "../auth/ForgotPassword";
import ResetPassword from "../auth/ResetPassword";
import SignUp from "../auth/SignUp";
import Contact from "../contact/Contact";
import Book from "../books/Book";
import Events from "../events/Events";
import Reviews from "../news/Reviews";
import { useAuthContext } from "../../contexts/AuthContext";
import { Box, Button, Typography } from "@mui/material";
import OtherWork from "../otherWork/OtherWork";
import FurtherResources from "../books/FurtherResources";
import { Auth } from "aws-amplify";

const Navigation = () => {
  const { dbUser, synced } = useAuthContext();

  return (
    <Router>
      <div style={{ backgroundColor: colors.secondaryBackground }}>
        {dbUser?.access === "admin" && (
          <Box
            sx={{
              right: 0,
              position: "fixed",
              bottom: 0,
              borderTopLeftRadius: 10,
              gap: 2,
              zIndex: 3,
              backgroundColor: "#222222",
              p: 3,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ color: "white" }} variant="h5">
              You are currently in editing mode
            </Typography>
            <Button
              onClick={() => Auth.signOut()}
              sx={{ ml: "auto" }}
              variant="outlined"
            >
              Exit Editing Mode
            </Button>
          </Box>
        )}
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<Bio />} />\
          <Route path="/news" element={<News />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/books/:book" element={<Book />} />
          <Route path="/events" element={<Events />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/confirm/:username" element={<ConfirmSignUp />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/reset/:username" element={<ResetPassword />} />
          <Route path="/other-work" element={<OtherWork />} />
          <Route
            path="/books/happiness_falls/further_resources"
            element={<FurtherResources />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: "100svh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>404 Error | Page not found</h1>
      <Button onClick={() => navigate("/")} variant="contained" size="large">
        Back to Home
      </Button>
    </div>
  );
}

export default Navigation;
