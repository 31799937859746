
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { Auth, DataStore } from "aws-amplify"
import { useNavigate } from "react-router-dom"
import { User } from "../../models";

const Login = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false)
  const [emailError, setEmailError] = React.useState(null)
  const [passwordError, setPasswordError] = React.useState(null)

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let error = false;
    if (data.get("email") === "") {
      setEmailError({ message: "Email cannot be empty" });
      error = true;
    } else if (
      !data.get("email").includes("@") ||
      !data.get("email").includes(".")
    ) {
      setEmailError({ message: "Email not valid" });
      error = true;
    } else {
      setEmailError(null);
    }
    if (data.get("password") === "") {
      setPasswordError({ message: "Password cannot be empty" });
      error = true;
    } else if (data.get("password").length < 8) {
      setPasswordError({ message: "Password must be at least 8 characters" });
      error = true;
    } else if (data.get("password").includes(" ")) {
      setPasswordError({ message: "Password cannot contain spaces" });
      error = true;
    }
    else {
      setPasswordError(null);
    }
    if (error) {
      setLoading(false);
      return;
    }


    try {
      await Auth.signIn(data.get('email'), data.get('password'))
      navigate("/")
    }
    catch (err) {
      setLoading(false)
      console.log(err)
      if(err.message === "User does not exist.") {
        setEmailError({message: "Login combination not valid"});
        setPasswordError({ message: "Login combination not valid" });
      }
      if (
        err.message.toLowerCase().includes("username")
      ) {
        setEmailError({ message: "Email not valid" });
      } 
      if(err.message.toLowerCase().includes("password")){
        setPasswordError({ message: "Password not valid" });
      }

      if(err.code === "UserNotConfirmedException") {
        DataStore.query(User, u => u.email.eq(data.get('email'))).then((user) => {
          if(user) {
            Auth.resendSignUp(data.get('email'))
            navigate('/confirm/' + data.get('email'))
          }
      })
      }

    }
  }

  return (
      <Container component="main" maxWidth="s">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              color="secondary"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              inputProps={{autoCapitalize: "none"}}
              error = {emailError ? true : false}
              helperText = {emailError ? emailError.message : null}
            />
            <TextField
              color="secondary"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error = {passwordError ? true : false}
              helperText = {passwordError ? passwordError.message : null}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="secondary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ mt: 3, mb: 2, color: "white", fontWeight: "bold", }}
            >
              {loading ? 'Loading...' : 'Login'}
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link color={'#1e5262'} href="/forgot" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link color={'#1e5262'} href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
      </Container>
  );
}

export default Login;