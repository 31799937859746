import "./App.css";
import Navigation from "./pages/navigation/Navigation";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { colors } from "./theme/colors";
import { Amplify } from "aws-amplify";
import AuthContextProvider from "./contexts/AuthContext";

function App() {
  Amplify.configure({
    aws_project_region: "us-east-1",
    aws_appsync_graphqlEndpoint:
      "https://37gbmko7uzfb7ofsjyb2vtklry.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "API_KEY",
    aws_appsync_apiKey: "da2-exvze55c2nfrfhaloanowr72ja",
    aws_cognito_identity_pool_id:
      "us-east-1:e8d9201b-5ea7-4edc-b8a5-9fad2f844ed6",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_12XqtPjG1",
    aws_user_pools_web_client_id: "2ivnnv9auc6p13sl74b0rejgva",
    oauth: {},
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: [],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
  });

  const theme = createTheme({
    palette: {
      primary: {
        main: colors.primaryColor,
      },
      secondary: {
        main: colors.secondaryColor,
      },
    },
    typography: {
      h1: {
        fontSize: "3em",
        fontFamily: "Mocha_Mattari",
        margin: 0,
        marginBlock: 0,
      },
      p: {
        fontSize: "1em",
        fontFamily: "TisaSansPro",
        margin: 0,
        marginBlock: 0,
      },
      fontFamily: ["TisaSansPro"],
    },
  });
  return (
    <div className="App">
      <AuthContextProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Navigation className="App" />
        </ThemeProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
