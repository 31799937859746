import "./Footer.css";
import SocialList from "./SocialList";

const Footer = () => {
  return (
    <div className="footer-container">
      <h1 style={{ fontSize: "2.5em" }}>ANGIE KIM</h1>{" "}
      {/* we can add bottom nav back in if we rly want */}
      <SocialList width={"10%"} />
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <Link className="link" to={"/about"}>
          About Us
        </Link>

        <Link className="link" to={"/contact"}>
          Contact Us
        </Link>
        <Link className="link" to={"/legal"}>
          Terms
        </Link>
      </div> */}
      <p
        style={{
          fontSize: "1em",
          marginBottom: "2em",
        }}
      >
        {`Copyright © ${new Date().getFullYear()} Angie Kim. Site Design:`}{" "}
        <a
          className="footer-button"
          onClick={() =>
            window.open("https://www.draughnalink.com", "_blank", "noreferrer")
          }
        >
          Draughn & Alink LLP.
        </a>
      </p>
    </div>
  );
};

export default Footer;
