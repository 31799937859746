import { Box, Button, Grid, Typography } from "@mui/material";
import Footer from "../components/Footer";
import PublicHeader from "../components/PublicHeader";

import "./Landing.css";
import { useEffect, useState } from "react";
import { happiness_falls_retailers } from "../assets/retailers";
import { bookData } from "../assets/bookData";
import { colors } from "../theme/colors";
import { useNavigate } from "react-router-dom";

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Countdown from "../components/Countdown";

const Landing = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loadedIn, setLoadedIn] = useState(false);

  const mcBookData = bookData.find(
    (book) => book.title.toLowerCase() === "miracle creek"
  );

  useEffect(() => {
    setData(
      bookData.find((book) => book.title.toLowerCase() === "happiness falls")
    );

    setLoadedIn(true);
  }, []);

  if (!data.title) {
    return null;
  }

  return (
    <Box
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      className={`container ${loadedIn ? "loadedIn" : ""}`}
    >
      <PublicHeader currentRoute={"/"} />

      <Box
        className="container anim"
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          paddingTop: "7em",
        }}
      >
        <Box
          className="sectionContainer anim"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Box className="anim-container">
            <img
              src={require("../assets/WavesOriginal.png")}
              className="cover waves"
            ></img>
            <img
              src={require("../assets/coverImageWaves.png")}
              className="cover waves ribbon"
            ></img>

            <img
              src={require("../assets/coverBackground.png")}
              className="cover background"
            ></img>

            <img
              src={require("../assets/coverImageForest.png")}
              className="cover forest ribbon"
            ></img>
            <img
              src={require("../assets/Forest.jpg")}
              className="cover forest"
            ></img>
            <img
              src={require("../assets/coverText.png")}
              className="cover text"
            ></img>
          </Box>
        </Box>
      </Box>
      <Box style={{ padding: "7em 0em" }} className="sectionContainer">
        <Grid container wrap="wrap-reverse">
          <Grid
            item
            xl={6}
            lg={5}
            xs={12}
            style={{ flex: 1, display: "flex", flexDirection: "column" }}
          >
            <Box
              sx={{
                mt: "3em",
                mb: "2em",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography
                variant="p"
                style={{
                  fontSize: "1.65em",
                  fontWeight: 500,
                }}
              >
                Instant New York Times Bestseller
              </Typography>
              <Typography
                variant="p"
                style={{
                  fontSize: "1.65em",
                  fontWeight: 500,
                }}
              >
                Good Morning America Book Club Pick
              </Typography>
              <Typography
                variant="p"
                style={{
                  fontSize: "1.65em",
                  fontWeight: 500,
                }}
              >
                Barnes & Noble Book Club Pick
              </Typography>
              <Typography
                variant="p"
                style={{
                  fontSize: "1.65em",
                  fontWeight: 500,
                }}
              >
                Belletrist Book Club Pick
              </Typography>
              <Typography
                variant="p"
                style={{
                  fontSize: "1.65em",
                  fontWeight: 500,
                }}
              >
                Finalist, New American Voices Award
              </Typography>
              <Typography
                variant="p"
                style={{
                  fontSize: "1.65em",
                  fontWeight: 500,
                }}
              >
                "I read this in a single day...A story with so many twists and
                turns I was riveted through the last page." - Jodi Picoult
              </Typography>
              <Typography
                variant="p"
                style={{
                  fontSize: "1.65em",
                  fontWeight: 500,
                }}
              >
                "I loved this book." - Gabrielle Zevin, author of Tomorrow, and
                Tomorrow, and Tomorrow
              </Typography>

              <Box
                className="pre-order-container"
                style={{
                  backgroundColor: `${colors.secondaryColor}22`,
                  borderRadius: 8,
                  padding: "2em 1em",
                  marginTop: "3em",
                }}
              >
                <Grid container className="preorder-list">
                  {happiness_falls_retailers.map((retailer, index) => {
                    return (
                      <Grid
                        item
                        style={{
                          padding: "0em 1em",
                        }}
                        className="preorder-image-container"
                      >
                        <Box style={{ padding: "5px" }}>
                          <img
                            style={{
                              maxWidth: "100%",
                              height: index === 0 ? "4em" : "2em",
                              resize: "vertical",
                            }}
                            className={`preorder-image ${
                              index === 0 ? "no-greyscale" : ""
                            }`}
                            src={retailer.image}
                            onClick={() => {
                              window.open(
                                retailer.link,
                                "_blank",
                                "noreferrer"
                              );
                            }}
                          ></img>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          </Grid>

          <Grid item xl={6} lg={7} xs={12} sx={{}}>
            <img //BOOK COVER
              className="cover"
              src={require("../assets/HappinessFallsCover.jpg")}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        style={{ padding: "6em 0em", width: "100%" }}
        className="background-image"
      >
        <Box style={{ width: "94%" }} className="slide-container">
          <Slide duration={10000} pauseOnHover={false}>
            {[...data.blurbs.filter((blurb, index) => index % 3 === 1)].map(
              (blurb, index) => (
                <Box>
                  <Box
                    style={{
                      color: "white",
                      textAlign: "center",
                      width: "100%",

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <br />
                    <span style={{ fontSize: "2em" }}>
                      "{data.blurbs[3 * index].text}"<br />
                    </span>
                    <span
                      style={{
                        fontSize: "2em",
                        fontStyle: "italic",
                        color: colors.primaryColor,
                      }}
                    >
                      <b>{data.blurbs[3 * index].author}</b>,{" "}
                      <span style={{ fontSize: ".75em" }}>
                        {data.blurbs[3 * index].authorSubTitle}
                        <br></br>
                      </span>
                    </span>
                  </Box>
                  {data.blurbs?.[3 * index + 1] && (
                    <Box
                      style={{
                        color: "white",
                        textAlign: "center",
                        width: "100%",

                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <br />
                      <span style={{ fontSize: "2em" }}>
                        "{data.blurbs?.[3 * index + 1].text}"<br />
                      </span>
                      <span
                        style={{
                          fontSize: "2em",
                          fontStyle: "italic",
                          color: colors.primaryColor,
                        }}
                      >
                        <b>{data.blurbs?.[3 * index + 1].author}</b>,{" "}
                        <span style={{ fontSize: ".75em" }}>
                          {data.blurbs?.[3 * index + 1].authorSubTitle}
                          <br></br>
                        </span>
                      </span>
                    </Box>
                  )}
                  {data.blurbs?.[3 * index + 2] && (
                    <Box
                      style={{
                        color: "white",
                        textAlign: "center",
                        width: "100%",

                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <br />
                      <span style={{ fontSize: "2em" }}>
                        "{data.blurbs?.[3 * index + 2].text}"<br />
                      </span>
                      <span
                        style={{
                          fontSize: "2em",
                          fontStyle: "italic",
                          color: colors.primaryColor,
                        }}
                      >
                        <b>{data.blurbs?.[3 * index + 2].author}</b>,{" "}
                        <span style={{ fontSize: ".75em" }}>
                          {data.blurbs?.[3 * index + 2].authorSubTitle}
                          <br></br>
                        </span>
                      </span>
                    </Box>
                  )}
                </Box>
              )
            )}
          </Slide>
        </Box>
      </Box>

      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box className="sectionContainer" style={{ paddingTop: "7em" }}>
          <Box>
            <Box style={{}} className="sectionContainer books">
              <img
                className="cover"
                src={require(`../assets/${mcBookData?.image}`)}
                alt="miracle creek"
              ></img>

              <Box
                className="book-description"
                style={{ justifyContent: "space-around" }}
              >
                <h1
                  style={{
                    fontSize: "3em",
                    padding: "0em 0em",
                    color: colors.primaryColor,
                  }}
                >
                  {"Available Now"}
                </h1>
                <h1
                  style={{
                    fontSize: "3em",
                    padding: "0em 0em",
                    color: colors.primaryColor,
                  }}
                >
                  {"International Bestseller"}
                </h1>
                <Box>
                  <h1
                    style={{
                      fontSize: "3em",
                      padding: "0em 0em",
                      color: colors.primaryColor,
                    }}
                  >
                    {"Award Winner"}
                  </h1>
                  <p
                    style={{
                      textAlign: "left",
                      fontSize: "1.8em",
                    }}
                  >
                    {mcBookData.featuredAwards.join(" • ")}
                  </p>
                </Box>
                <Box>
                  <h1
                    style={{
                      fontSize: "3em",
                      padding: "0em 0em",
                      color: colors.primaryColor,
                    }}
                  >
                    {"A Best Book of the Year"}
                  </h1>
                  <p
                    style={{
                      textAlign: "left",
                      fontSize: "1.8em",
                    }}
                  >
                    {mcBookData.bestBookOfTheYear.join(" • ")}
                  </p>
                </Box>

                <Box
                  style={{ padding: 0, margin: 0, marginTop: "-2em" }}
                  className="slide-container"
                >
                  <Slide arrows={false} duration={4000}>
                    {mcBookData.featuredReviews.map((review, i) => (
                      <Box>
                        <Box style={{}}>
                          <span
                            style={{ fontSize: "2em", textAlign: "center" }}
                          >
                            <br />"{review.text}"
                            <br />
                            <span
                              onClick={() =>
                                window.open(review.link, "_blank", "noreferrer")
                              }
                              className="review-author"
                              style={{
                                fontSize: ".8em",
                                fontFamily: "TisaSansPro-Bold",
                                fontStyle: "italic",
                                color: colors.primaryColor,
                              }}
                            >
                              {review.author} {review.authorSubTitle}
                            </span>
                          </span>
                        </Box>
                      </Box>
                    ))}
                  </Slide>
                </Box>
                <Button
                  size="large"
                  color="secondary"
                  variant="contained"
                  sx={{ fontSize: "1.2em", alignSelf: "flex-start" }} //will scroll down
                  onClick={() => {
                    window.open("/books/miracle_creek", "_blank", "noreferrer");
                  }}
                >
                  See More
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Landing;
