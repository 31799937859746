import { FacebookTwoTone, Instagram, Twitter } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { DataStore, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { FaGoodreadsG } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CustomImageList from "../../components/CustomImageList";
import Footer from "../../components/Footer";
import NewsModule from "../../components/NewsModule";
import PublicHeader from "../../components/PublicHeader";
import { Post } from "../../models";
import { colors } from "../../theme/colors";
import "./Bio.css";

const Bio = () => {
  const [itemData, setItemData] = useState([]);
  const [loadedIn, setLoadedIn] = useState(false);

  useEffect(() => {
    setLoadedIn(true);
    DataStore.query(Post, (p) => p.type.eq("BIO"), {
      sort: (p) => p.featured(SortDirection.ASCENDING),
    }).then(setItemData);
  }, []);

  const navigate = useNavigate();

  return (
    <div className={`container ${loadedIn ? "loadedIn" : ""}`}>
      <PublicHeader currentRoute={"/about"} />
      <div
        style={{
          padding: "7em 0em",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="sectionContainer bio">
          <img //BOOK COVER
            className="cover author"
            src={require("../../assets/AuthorPhoto.jpg")}
          />
          <div className="description">
            <h1 className="bio-header">BIO</h1>
            <span className="author-bio">
              <span
                style={{
                  fontFamily: "TisaSansPro-Bold",
                  color: colors.primaryColor,
                }}
              >
                Angie Kim&nbsp;
              </span>
              moved as a preteen from Seoul, South Korea, to the suburbs of
              Baltimore. After graduating from Interlochen Arts Academy, she
              studied philosophy at Stanford University and attended Harvard Law
              School, where she was an editor of the <i>Harvard Law Review</i>.
              Her debut novel,{" "}
              <i>
                <b
                  className="link bio"
                  style={{ color: colors.primaryColor }}
                  onClick={() => navigate("/books/miracle_creek")}
                >
                  Miracle Creek
                </b>
              </i>
              , won the Edgar Award and the ITW Thriller Award, and was named
              one of the 100 best mysteries and thrillers of all time by Time,
              and one of the best books of the year by Time, The Washington
              Post, Kirkus Reviews, and the Today show. Happiness Falls, her
              second novel, was an instant New York Times bestseller and a book
              club pick for Good Morning America, Barnes & Noble, Belletrist,
              and Book of the Month Club.
            </span>
            <div
              className="pre-order-container bio"
              style={{
                borderRadius: 8,
                padding: "1em 1em",
              }}
            >
              <h2
                style={{
                  fontSize: "2em",

                  marginBottom: "1em",
                }}
              >
                Connect with Angie
              </h2>

              <Grid
                container
                xs={12}
                style={{ justifyContent: "center" }}
                spacing={1}
              >
                <Grid item>
                  <FacebookTwoTone
                    className="icon"
                    color="primary"
                    sx={{ fontSize: "3em" }}
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/angie.kim.va",
                        "_blank",
                        "noreferrer"
                      )
                    }
                  />
                </Grid>
                <Grid item>
                  <Instagram
                    className="icon"
                    color="primary"
                    sx={{ fontSize: "3em" }}
                    onClick={() =>
                      window.open(
                        "https://www.goodreads.com/author/show/18035146.Angie_Kim",
                        "_blank",
                        "noreferrer"
                      )
                    }
                  />
                </Grid>
                <Grid item>
                  <Twitter
                    className="icon"
                    color="primary"
                    sx={{ fontSize: "3em" }}
                    onClick={() =>
                      window.open(
                        "https://twitter.com/AngieKimWriter",
                        "_blank",
                        "noreferrer"
                      )
                    }
                  />
                </Grid>
                <Grid item>
                  <FaGoodreadsG
                    size={"2.5em"}
                    style={{ marginTop: ".25em" }}
                    className="icon"
                    color={colors.primaryColor}
                    onClick={() =>
                      window.open(
                        "https://www.goodreads.com/book/show/40121959-miracle-creek",
                        "_blank",
                        "noreferrer"
                      )
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className="sectionContainer bio two">
          <div>
            <h1 className="bio-header two">PROFILES & INTERVIEWS</h1>
          </div>

          {itemData.map((item) => {
            return (
              <NewsModule
                title={item.title}
                description={item.body}
                link={item.link}
                linkTitle={item.linkText}
                image={item.image}
              />
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Bio;
