import { FacebookTwoTone, Instagram, Twitter } from "@mui/icons-material";
import { Card, CardContent, Grid, Typography, Button } from "@mui/material";
import { FaGoodreadsG } from "react-icons/fa";
import { colors } from "../theme/colors";
import "./SocialList.css";

const SocialList = ({ size, width }) => {
  return (
    <Grid className = "social-list" container xs={12} style={{width: '250px'}}>
      <Grid item xs={3}>
        <FacebookTwoTone
          className="icon"
          color="primary"
          sx={{ fontSize: "3em" }}
          onClick={() =>
            window.open(
              "https://www.facebook.com/angie.kim.va",
              "_blank",
              "noreferrer"
            )
          }
        />
      </Grid>
      <Grid item xs={3}>
        <Instagram
          className="icon"
          color="primary"
          sx={{ fontSize: "3em" }}
          onClick={() =>
            window.open(
              "https://www.instagram.com/angiekimask/",
              "_blank",
              "noreferrer"
            )
          }
        />
      </Grid>
      <Grid item xs={3}>
        <Twitter
          className="icon"
          color="primary"
          sx={{ fontSize: "3em" }}
          onClick={() =>
            window.open(
              "https://twitter.com/AngieKimWriter",
              "_blank",
              "noreferrer"
            )
          }
        />
      </Grid>
      <Grid item xs={3}>
        <FaGoodreadsG
          size={"2.5em"}
          style={{ marginTop: ".25em" }}
          className="icon"
          color={colors.primaryColor}
          onClick={() =>
            window.open(
              "https://www.goodreads.com/book/show/40121959-miracle-creek",
              "_blank",
              "noreferrer"
            )
          }
        />
      </Grid>
    </Grid>
  );
};

export default SocialList;
