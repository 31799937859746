export const bookData = [
  {
    title: "Miracle Creek",
    description1: `In a small town in Virginia, a group of strangers come together at a special treatment center, where they enter the Miracle Submarine, an experimental oxygen chamber that may cure a range of conditions—from infertility to autism. Then the chamber  explodes and two people die. Who is responsible? Was it the exhausted mother of a patient? The owners, hoping to cash in on a big insurance payment and send their daughter to college? Could it have been a protestor, trying to prove that the treatment isn’t safe? An ensuing murder trial uncovers unimaginable secrets and lies.`,
    description2: `Drawing on the author’s own experiences—as a former trial lawyer, Korean American immigrant, and mother of a real-life “submarine” patient—Miracle Creek pieces together the tense atmosphere of a courtroom drama and the complexities of family life. It’s a powerful debut from an unforgettable new voice.`,
    quote:
      "My husband asked me to lie. Not a big lie. He probably didn’t even consider it a lie, and neither did I, at first . . .",
    highlight:
      "How far will you go to protect your family? Will you keep their secrets? Ignore their lies?",
    links: [
      {
        title: "Politics & Prose (Signed)",
        link: "https://www.politics-prose.com/book/9780374156022signed",
      },
    ],
    image: "MiracleCreek.JPEG",
    featuredAwards: [
      "2020 Edgar Award for First Novel",
      "2020 ITW Thriller Award for Best First Novel",
      "2020 Strand Critics Award for Best Debut Novel",
      "2020 Pinckley Prize for Debut Novel",
    ],
    awards: "",
    translations: 20,
    mainHighlights: ["International Bestseller &", "Winner, Edgar Award"],
    featuredReviews: [
      {
        text: "A fascinating study of the malleability of truth",
        author: "The New York Times Book Review",
        link: "https://www.nytimes.com/2019/05/01/books/review/miracle-creek-angie-kim.html",
      },
      {
        text: "A stunning debut. A deeply moving story about parents and the lengths they will go for their children",
        author: "The Washington Post",
        link: "https://www.washingtonpost.com/entertainment/books/miracle-creek-a-twisty-legal-thriller-that-shows-how-far-parents-will-go-for-their-kids/2019/04/16/b4a5e98a-4506-11e9-aaf8-4512a6fe3439_story.html?utm_term=.6690e05b8769",
      },
      {
        text: "A mesmerizing debut...[w]ith clear, assured prose and penetrating emotional intelligence",
        author: "The Los Angeles Times",
        link: "https://www.latimes.com/books/la-et-jc-miracle-creek-angie-kim-20190510-story.html",
      },
      {
        text: "Gripping... Kim makes a case for compassion that surpasses the suspense of her page-turner",
        author: "TIME Magazine",
        link: "https://time.com/collection-post/5724527/miracle-creek/",
      },
    ],
    bestBookOfTheYear: [
      "Time",
      "The Washington Post",
      "Today show",
      "Real Simple",
      "Kirkus Reviews",
      "Library Journal",
      "Bookpage",
      "Crimereads",
      "Chicago Public Library",
      "Amazon",
      "Hudson Booksellers",
    ],
    honors: [
      "Goodreads Choice Award Finalist",
      "New American Voices Award Finalist",
      "Anthony Award Finalist",
      "Library of Virginia Literary Award Finalist",
      "IndieNext Pick",
      "LibraryReads Selection",
      "Junior Library Guild Pick",
      "Book of the Month Club",
      "Amazon Editors’ Pick",
    ],
    listsAndRecs: [
      "People Pick: The Best New Books",
      "Good Morning America Hot Summer Read",
      "Apple Books 2019 Top Ten Debuts",
      "Washington Post Summer Reading",
      "ELLE Reading List",
      "Real Simple The Short List",
      "Southern Living Best Spring Books",
      "Entertainment Weekly April Jewels",
      "O, The Oprah Magazine Best Recent Mystery Novels",
      "The Saturday Evening Post 10 Best Spring Books",
    ],
  },
  {
    title: "Happiness Falls",
    description1: `Those are the
    electric first words of this extraordinary novel about a biracial
    Korean-American family in Virginia whose lives are upended when
    their beloved father and husband goes missing.
  
    `,
    description2: ` Mia, the irreverent, hyperanalytical twenty-year-old
    daughter, has an explanation for everything—which is why she
    isn't initially concerned when her father and younger brother
    Eugene don't return from a walk in a nearby park. They must have
    lost their phone. Or stopped for an errand somewhere. But by the
    time Mia's brother runs through the front door bloody and alone,
    it becomes clear that the father in this tight-knit family is
    missing and the only witness is Eugene, who has the rare genetic
    condition Angelman syndrome and cannot speak.`,
    description3: `What follows is both a ticking-clock investigation into the
    whereabouts of a father and an emotionally rich portrait of a family
    whose most personal secrets just may be at the heart of his
    disappearance. Full of shocking twists and fascinating questions of
    love, language, and human connection, Happiness Falls is a mystery,
    a family drama, and a novel of profound philosophical inquiry. With
    all the powerful storytelling she brought to her award-winning
    debut Miracle Creek, Angie Kim turns the missing person story into
    something wholly original, creating an indelible tale of a family
    who must go to remarkable lengths to truly understand each other.`,
    quote:
      "My husband asked me to lie. Not a big lie. He probably didn’t even consider it a lie, and neither did I, at first . . .",
    highlight:
      "How far will you go to protect your family? Will you keep their secrets? Ignore their lies?",
    links: [
      {
        title: "Politics & Prose (Signed)",
        link: "https://www.politics-prose.com/book/9780374156022signed",
      },
    ],
    blurbs: [
      {
        text: "Riveting, revelatory masterpiece by one of the finest novelists working today.",
        author: "Anthony Marra",
        authorSubTitle: "NYT bestselling author",
      },
      {
        text: "One of the best mystery novels ever written.... What Dostoevsky might have written if he had been an American and also not a jerk.",
        author: "Gary Shteyngart",
        authorSubTitle: "NYT bestselling author",
      },
      {
        text: "Brilliant, immersive, and deeply moving. It's a book that will change the way you think — a book that will change your life.",
        author: "Julia Phillips",
        authorSubTitle: "National Book Award finalist",
      },
      {
        text: "The most moving and humbling portrait of humanity I've read in quite some time... a flame of hope and life.",
        author: "Mary Beth Keane",
        authorSubTitle: "NYT bestselling author",
      },
      {
        text: "An exhilarating literary tour de force... extraordinary characters whose lives will leave you forever changed.",
        author: "Danielle Trussoni",
        authorSubTitle: "NYT bestselling author",
      },
      {
        text: "A riveting page turner... You will fall in love with this family-and this novel. It's a treasure.",
        author: "Chris Bohjalian",
        authorSubTitle: "#1 NYT bestselling author",
      },
      {
        text: "A sublime literary mystery that is a mesmerizing update to the missing person story, a layered and innovative exploration of family, love, happiness and race.",
        author: "Jean Kwok",
        authorSubTitle: "NYT bestselling author",
      },
      {
        text: "Profound and moving. This book will stay with you long after you turn the final, satisfying page.",
        author: "Alafair Burke",
        authorSubTitle: "NYT bestselling author",
      },
      {
        text: "A profound meditation on the meaning of life and the nature of happiness. A riveting mystery replete with suspense and peril. A remarkable achievement.",
        author: "Chris Pavone",
        authorSubTitle: "NYT bestselling author",
      },
      {
        text: "That rare book that can change your entire outlook on the world.",
        author: "Janelle Brown",
        authorSubTitle: "NYT bestselling author",
      },
      {
        text: "Thoughtful and nuanced, moving... gripping.",
        author: "Vikram Jaswal",
        authorSubTitle: "Professor of Psychology, University of Virginia",
      },
      {
        text: "Kim is a tremendous writer with a particular gift for conveying the dramatic churn of families in crisis...",
        author: "Steph Cha",
        authorSubTitle: "Award-winning author",
      },
      {
        text: "A freshness and originality to Angie Kim’s work that brings joy to the reader.... Happiness Falls is another superlative effort in what is fast shaping up to be a remarkable career.",
        author: "David Baldacci",
        authorSubTitle: "#1 NYT bestseller",
      },
      {
        text: "The best kind of mystery—one that keeps you thinking and guessing and feeling all at the same time.",
        author: "Liz Moore",
        authorSubTitle: "NYT bestselling author",
      },
    ],
    image: "HappinessFallsCover.jpg",
    featuredAwards: [],
    awards: "",
    translations: 0,
    mainHighlights: [],
    featuredReview: {
      text: "Riveting, revelatory masterpiece by one of the finest novelists working today.",
      author: "Anthony Marra",
      authorSubTitle: "NYT bestselling author",
      link: "https://www.nytimes.com/2019/05/01/books/review/miracle-creek-angie-kim.html",
    },
    featuredReviews: [],
    bestBookOfTheYear: [],
    criticalAcclaim: [
      {
        text: "Kim’s first novel was a fantastic work of family, crime, love, and loss, and she returns again to that combination to blow our minds with this incredible follow up.... Given how great Miracle Creek is, I was ready to be amazed, but this is a next-level dazzling feat of storytelling. Kim expertly teases out the mystery of Mia’s missing father and his secrets, while also telling the story of family and love, resentment, and fierce loyalty. It’s simply a heart-squeezing stunner.",
        author: "Book Riot's",
        authorSubTitle: "The Best Books We Read Jan-March 2023",
      },
      {
        text: "Greta, Liberty, and Lupita absolutely loved this novel.... [A] riveting page-turner sure to spark conversation around ableism and disability justice.",
        author: "The Nerdette Podcast",
        authorSubTitle: "Your Summer Books 2023 Preview",
      },
    ],
  },
];
