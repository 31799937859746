import { Link, useNavigate } from "react-router-dom";
import { colors } from "../theme/colors";
import "./PublicHeader.css";
import { GoThreeBars } from "react-icons/go";
import { useEffect, useState } from "react";
import { Drawer, IconButton } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useAuthContext } from "../contexts/AuthContext";
import { Auth } from "aws-amplify";

const PublicHeader = ({ currentRoute }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loadedIn, setLoadedIn] = useState(false); //for fade in
  const { dbUser } = useAuthContext();

  useEffect(() => {
    //establishes a listener for window width
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
    setLoadedIn(true);
    return () =>
      window.removeEventListener("resize", setWindowWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <div
      style={{
        ...styles.header,
        backgroundColor: scrollPosition > 0 ? "#E3F1F2" : "transparent",
        boxShadow: scrollPosition > 0 ? `0px 2px 4px #00000022` : "none", // Apply boxShadow conditionally
        transition: "all .3s ease-in-out",
        zIndex: 4,
      }}
      className={`to-load-in ${loadedIn ? "loadedIn" : ""}`}
    >
      <div
        className="logo-wrapper"
        onClick={() => navigate("/")}
        style={{ marginLeft: 30 }}
      >
        <h1
          style={{ letterSpacing: "2e", fontSize: "5.5em" }}
          className="header"
        >
          Angie Kim
        </h1>
      </div>

      {windowWidth > 1100 ? (
        <div
          style={{
            display: "flex",
            gap: ".5em",
            marginLeft: "auto",
            marginRight: "2em",
            fontSize: "1.5em",
          }}
        >
          <Link
            className={`link ${currentRoute === "/" ? "focused" : ""}`}
            to="/"
          >
            Home
          </Link>
          <Link
            className={`link ${currentRoute === "/about" ? "focused" : ""}`}
            to="/about"
          >
            About
          </Link>

          <div className="dropdown">
            <Link
              className={`book link ${
                currentRoute === "/books" ? "focused" : ""
              }`}
              to="/books/happiness_falls"
            >
              Books
            </Link>

            <div className="dropdown-content">
              <Link
                className="dropdown-content-link"
                to="/books/happiness_falls"
              >
                Happiness Falls
              </Link>
              <Link className="dropdown-content-link" to="/books/miracle_creek">
                Miracle Creek
              </Link>
            </div>
          </div>
          <Link
            className={`book link ${currentRoute === "/news" ? "focused" : ""}`}
            to="/news"
          >
            News
          </Link>

          <Link
            className={`link ${currentRoute === "/events" ? "focused" : ""}`}
            to="/events"
          >
            Events
          </Link>
          <Link
            className={`link ${
              currentRoute === "/other-work" ? "focused" : ""
            }`}
            to="/other-work"
          >
            Other Work
          </Link>
          <Link
            className={`link ${currentRoute === "/contact" ? "focused" : ""}`}
            to="/contact"
          >
            Contact
          </Link>
        </div>
      ) : (
        <GoThreeBars
          size="2.5em"
          style={{ marginLeft: "auto", marginRight: 30 }}
          onClick={() => setDrawerVisible(true)}
        />
      )}
      <Drawer
        anchor="right"
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
      >
        <div
          style={{
            padding: "1em 3em",
            display: "flex",
            flexDirection: "column",
            fontSize: "1.5em",
          }}
          onKeyDown={() => setDrawerVisible(false)}
        >
          <Link
            className={`link ${currentRoute === "/" ? "focused" : ""}`}
            to="/"
          >
            Home
          </Link>
          <Link
            className={`link ${currentRoute === "/about" ? "focused" : ""}`}
            to="/about"
          >
            About
          </Link>
          <div className="dropdown">
            <Link
              className={`book link ${
                currentRoute === "/books" ? "focused" : ""
              }`}
            >
              Books
            </Link>

            <div className="dropdown-content">
              <Link
                className="dropdown-content-link"
                to="/books/happiness_falls"
              >
                Happiness Falls
              </Link>
              <Link className="dropdown-content-link" to="/books/miracle_creek">
                Miracle Creek
              </Link>
            </div>
          </div>
          <Link
            className={`book link ${currentRoute === "/news" ? "focused" : ""}`}
            to="/news"
          >
            News
          </Link>

          <Link className="link" to="/events">
            Events
          </Link>
          <Link
            className={`link ${
              currentRoute === "/other-work" ? "focused" : ""
            }`}
            to="/other-work"
          >
            Other Work
          </Link>
          <Link
            className={`link ${currentRoute === "/contact" ? "focused" : ""}`}
            to="/contact"
          >
            Contact
          </Link>
        </div>
      </Drawer>
    </div>
  );
};

const styles = {
  header: {
    display: "flex",
    flexDirection: "row",
    height: "5.5em",

    alignItems: "center",
    width: "100%",
    position: "fixed",
    top: 0,
  },
};

export default PublicHeader;
